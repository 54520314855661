const styles = (theme) => ({
  parentContainer: {
    marginBottom: 120
  },
  container: {
    height: "100vh",
    paddingLeft: 0
  },
  gridContainer: {
    height: "100%",
    marginLeft: 0,
    paddingLeft: 0
  },
  leftMenu: {
    backgroundColor: "#f5faff",
    overflow: "hidden"
  },
  selectedComponentStyle: {
    fontSize: 25,
    color: "grey",
    cursor: "pointer"
  },
  unselectedComponentStyle: {
    fontSize: 20,
    color: "grey",
    cursor: "pointer"
  },
  componentHolder: {
    overflow: "auto",
    height: "100%"
  },
  saveBtnStyle: {
    borderRadius: "2px",
    margin: 5,
    height: "36px",
    marginTop: 10,
    textTransform: "none"
  },
  paper: {
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    height: "100%"
  },
  width100: {
    width: "100%"
  },
  marginTop20: {
    marginTop: 20
  },
  headerStyle: {
    color: "#9c9797",
    fontSize: 16,
    borderBottom: 0
  },
  textStyle: {
    color: "#807b7b",
    borderBottom: 0
  },
  padding10: {
    padding: 10
  },
  upgradeTextStyle: {
    fontSize: 14
  },
  upgradeButtonStyle: {
    margin: "auto",
    display: "block"
  },
  overflowx: {
    overflowX: "auto"
  },
  dashboardStyle: {
    width: 960,
    [theme.breakpoints.down("sm")]: {
      width: 600
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  countStyles: {
    textAlign: "center",
    padding: "10%",
    fontSize: 30,
    paddingTop: "20%"
  },
  textStyles: {
    textAlign: "center",
    padding: 10
  },
  textStyles1: {
    textAlign: "center",
    padding: 22
  }
});
export default styles;
