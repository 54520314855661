const firebaseConfig = {
  apiKey: "AIzaSyBlWLpUFbE_2oVKADXJLTTyMQhwfLsVBN4",
  authDomain: "referlistco.firebaseapp.com",
  databaseURL: "https://referlistco.firebaseio.com",
  projectId: "referlistco",
  storageBucket: "referlistco.appspot.com",
  messagingSenderId: "513745917785",
  appId: "1:513745917785:web:21852eeef6f36dfa365e48",
  measurementId: "G-SF51HS2VEQ"
};

export default firebaseConfig;
