// a library to wrap and simplify api calls
import apisauce from "apisauce";
import firebase from "firebase/app";

let SERVER_URL = "https://referlist.herokuapp.com/api";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  SERVER_URL = "http://localhost:3001/api";
}

// const SERVER_URL = "https://referlist.herokuapp.com/api";
// const SERVER_URL = "http://localhost:3001/api";

// our "constructor"
const create = (baseURL = SERVER_URL) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache"
    },
    // 10 second timeout...
    timeout: 10000
  });

  const createApiCall = (endpoint, params, callback) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post(endpoint, params).then((res) => {
          callback(res);
        });
        console.log("/" + endpoint);
      });
  };

  const createUnAuthenticatedGetCall = (endpoint, params, callback) => {
    api.get(endpoint, params).then((res) => {
      callback(res);
    });
    console.log("/" + endpoint);
  };

  const getWaitlistConfigByDomainName = (domainName, callback) => {
      const endpoint = 'waitlist-config/domain/' + domainName; 

      /**
       * Reason for keeping it unauthenticated is that, 
       * getWaitlistConfigByDomainName API can be called from anywhere, no firebase user
       * TODO: we can have simple JWT based or api-key authentication
       */
      createUnAuthenticatedGetCall(endpoint, null, callback);
  };

  const createUnAuthenticatedCall = (endpoint, params, callback) => {
    api.post(endpoint, params).then((res) => {
      callback(res);
    });
    console.log("/" + endpoint);
  };

  const createApiCallWithFormData = (endpoint, data, callback) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        data.append("idToken", idToken);
        api.post(endpoint, data).then((res) => {
          callback(res);
        });
        console.log("/" + endpoint);
      });
  };

  const registerUser = (callback) => {
    createApiCall("registeruser", {}, callback);
  };

  const getWaitlist = (domainName, callback) => {
    createApiCall("getwaitlist", { domainName }, callback);
  };

  const getWaitlistFromId = (waitlistId, callback) => {
    createApiCall("getwaitlistfromid", { waitlistId }, callback);
  };

  const resetEmailDomain = (waitlistId, callback) => {
    createApiCall("resetemaildomain", { waitlistId }, callback);
  };

  const saveWaitlistDetails = (
    waitlistId,
    domain,
    postmarkDomainName,
    redirectUrl,
    companyName,
    uploadedUrl,
    mailchimpDetails,
    selectedAudienceId,
    title,
    subtitle,
    body,
    shareMessage,
    verificationMessage,
    fontColor,
    backgroundColor,
    seedCount,
    airtableBase,
    airtableApiKey,
    antiSpamEnabled,
    callback
  ) => {
    createApiCall(
      "savewaitlistdetails",
      {
        waitlistId,
        domain,
        postmarkDomainName,
        redirectUrl,
        companyName,
        uploadedUrl,
        mailchimpDetails,
        selectedAudienceId,
        title,
        subtitle,
        body,
        shareMessage,
        verificationMessage,
        fontColor,
        backgroundColor,
        seedCount,
        airtableBase,
        airtableApiKey,
        antiSpamEnabled
      },
      callback
    );
  };

  const addToWaitlist = (email, referralSource, waitlistName, callback) => {
    createUnAuthenticatedCall(
      "addtowaitlist",
      { email, referralSource, waitlistName },
      callback
    );
  };

  const position = (email, waitlistName, callback) => {
    createUnAuthenticatedCall("position", { email, waitlistName }, callback);
  };

  const verifyEmail = (token, callback) => {
    createUnAuthenticatedCall("verify", { token }, callback);
  };

  const getSignups = (waitlistId, limit, offset, joinedSort, callback) => {
    createApiCall(
      "getsignups",
      { waitlistId, limit, offset, joinedSort },
      callback
    );
  };

  const getPlan = (callback) => {
    createApiCall("plan", {}, callback);
  };

  const getClientAccount = (callback) => {
    createApiCall("clientaccount", {}, callback);
  };

  const exportData = (waitlistId) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        window.open(
          SERVER_URL +
            "/exportdata?idToken=" +
            idToken +
            "&waitlistId=" +
            waitlistId
        );
      });
  };

  const saveCard = (stripeToken, callback) => {
    createApiCall(
      "savecard",
      {
        stripeToken: stripeToken
      },
      callback
    );
  };

  const subscribetoplan = (plan, callback) => {
    createApiCall(
      "subscribetoplan",
      {
        plan: plan
      },
      callback
    );
  };

  const connectToMailchimp = (waitlistId) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        // GET request, not POST so not using createApiCall
        window.open(
          SERVER_URL +
            "/connecttomailchimp?idToken=" +
            idToken +
            "&waitlistId=" +
            waitlistId,
          "_self"
        );
      });
  };

  const getMailchimpAccessToken = (code, waitlistId, callback) => {
    createApiCall(
      "getmailchimpaccesstoken",
      {
        code,
        waitlistId
      },
      callback
    );
  };

  const exportToMailchimp = (waitlistId, callback) => {
    createApiCall("exporttomailchimp", { waitlistId }, callback);
  };

  const getUserAndWaitlists = (callback) => {
    createApiCall("getuserandwaitlists", {}, callback);
  };

  const refreshToken = (waitlistId, callback) => {
    createApiCall("refreshtoken", { waitlistId }, callback);
  };

  const getAudienceLists = (mailchimpDetails, callback) => {
    createApiCall("getaudiencelists", { mailchimpDetails }, callback);
  };

  const removeFromList = (signups, callback) => {
    createApiCall("removefromlist", { signups }, callback);
  };

  const setSignupAsWhitelisted = (signupId, whitelisted, callback) => {
    createApiCall(
      "setsignupaswhitelisted",
      { signupId, whitelisted },
      callback
    );
  };

  const importSignupsFromCSV = (file, waitlistId, callback) => {
    let data = new FormData();
    data.append("file", file);
    data.append("waitlistId", waitlistId);
    createApiCallWithFormData("importsignup", data, callback);
  };

  return {
    registerUser,
    getWaitlist,
    getWaitlistFromId,
    resetEmailDomain,
    saveWaitlistDetails,
    addToWaitlist,
    position,
    verifyEmail,
    getSignups,
    getPlan,
    getClientAccount,
    exportData,
    saveCard,
    subscribetoplan,
    connectToMailchimp,
    getMailchimpAccessToken,
    exportToMailchimp,
    getUserAndWaitlists,
    getAudienceLists,
    removeFromList,
    setSignupAsWhitelisted,
    importSignupsFromCSV,
    refreshToken,
    getWaitlistConfigByDomainName
  };
};

export default {
  create
};
