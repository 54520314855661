const images = {
  logo: require('../Images/logo.png'),
  queueImage: require('../Images/queue_illustration.png'),
  headerImage: require('../Images/logo_text_dark.png'),
  googleLogo: require('../Images/google_logo.png'),
  listIcon1: require('../Images/listicon1.png'),
  listIcon2: require('../Images/listicon2.png'),
  listIcon3: require('../Images/listicon3.png'),
  listIcon4: require('../Images/listicon4.png'),
  listIcon5: require('../Images/listicon5.png')
}

export default images