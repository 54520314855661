import Colors from "../../Themes/Colors";

const styles = {
  container: {
    backgroundColor: Colors.white,
    color: Colors.black,
    paddingBottom: 50,
    flex: 1,
    margin: 0,
    width: "100%",
    minHeight: "100vh"
  },
  copyButton: {
    marginTop: 20,
    marginLeft: 5,
    color: Colors.copyButton
  },
  main: {
    textAlign: "center"
  },
  position: {
    fontSize: 22
  },
  avatar: {
    position: "absolute",
    marginTop: "40px",
    marginBottom: "30px",
    left: "50%",
    marginLeft: "-50px",
    width: "100px",
    height: "100px"
  },
  referralLink: {
    border: "1px solid",
    padding: "10px",
    borderRadius: "5px",
    marginTop: "20px"
  },
  shareButtonStyle: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  bodyText: {
    whiteSpace: "pre-line"
  },
  margin10: {
    marginTop: 10
  },
  margin20: {
    marginTop: 20
  },
  margin50: {
    marginTop: 50
  },
  referralContainer: {
    marginBottom: 20
  },
  gobackButtonStyle: {
    border: "1px solid"
  }
};

export default styles;
