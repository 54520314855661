const useStyles = {
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold"
  },
  headerButtons: {
    paddingRight: 10,
    cursor: "pointer"
  },
  headerContainer: {
    marginTop: 10,
    marginBottom: 10
  },
  padding10: {
    padding: 10
  }
};

export default useStyles;
