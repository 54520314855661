import * as firebase from "firebase";
import {v4 as uuid} from "uuid"; 

export async function uploadImage(clientId, file) {
  if (file) {
    var fileName = file.name;
    try {
      var snapshot = await firebase
        .storage()
        .ref("waitlist/" + uuid.v4())
        .child(fileName)
        .put(file);

      let url = await snapshot.ref.getDownloadURL();
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
