export const planTypes = {
  FREE: "Free",
  GROWTH: "Growth",
  PRO: "Pro"
};

export const errorMessages = {
  MAILCHIMP_NOT_CONFIGURED: "mailchimp_not_configured"
};

export const signupsLimitToExport = {
  FREE_LIMIT: 100,
  GROWTH_LIMIT: 500,
  PRO_LIMIT: 0
};
