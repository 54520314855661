import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TableHead,
  Typography,
  TablePagination,
  Checkbox
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import styles from "./styles/SignupsStyles";
import API from "../Services/Api";
import ReactGA from "react-ga";
import Notifications, { notify } from "react-notify-toast";
import Header from "../Components/Header";
import ConfirmDialog from "../Components/ConfirmDialog";
import { formatForDisplay } from "../Utils/TimeUtils";
import { planTypes, errorMessages } from "../Utils/Types";
import { signupsLimitToExport } from "../Utils/Types";

const api = API.create();
const PAGE_LIMIT = 50;
const NOTIFICATION_LENGTH = 3000;

class Signups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitlist: props.waitlist,
      signups: [],
      selectedSignups: [],
      page: 0,
      rowsPerPage: PAGE_LIMIT,
      totalCount: PAGE_LIMIT,
      dataLoaded: false,
      user: props.user,
      paywallLimitImposed: false,
      referralCount: 0,
      confirmRemoveSignups: false,
      joinedSort: ""
    };

    this.fileUploadRef = React.createRef();
  }

  componentDidMount = () => {
    ReactGA.pageview("signups");
    this.load();
  };

  load = () => {
    if (this.state.waitlist !== null) {
      api.getSignups(
        this.state.waitlist._id,
        this.state.rowsPerPage,
        this.state.page * this.state.rowsPerPage,
        this.state.joinedSort,
        (res) => {
          if (res.status === 200) {
            this.setState({
              signups: res.data.result ? res.data.result.docs : [],
              selectedSignups: new Array(
                res.data.result ? res.data.result.docs.length : 0
              ).fill(false),
              totalCount: res.data.totalCount,
              pageCount: Math.ceil(res.data.totalCount / PAGE_LIMIT),
              dataLoaded: res.data.result ? true : false,
              user: res.data.user,
              referralCount: res.data.referralCount,
              paywallLimitImposed: res.data.paywallLimitImposed
            });
          }
        }
      );
    }
  };

  handleJoinedSort = () => {
    var joinedSort = this.state.joinedSort;

    if (joinedSort === "asc" || joinedSort === "") {
      joinedSort = "desc";
    } else {
      joinedSort = "asc";
    }

    this.setState({ joinedSort: joinedSort }, () => {
      this.load();
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, dataLoaded: false }, () => {
      this.load();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      { rowsPerPage: event.target.value, dataLoaded: false },
      () => {
        this.load();
      }
    );
  };

  handleChecked = (index, event) => {
    let { selectedSignups } = this.state;

    if (event.target.checked) {
      selectedSignups[index] = true;
    } else {
      selectedSignups[index] = false;
    }

    this.setState({
      selectedSignups
    });
  };

  showExportLimitAlert = () => {
    if (this.state.paywallLimitImposed) {
      if (this.state.user.billingDetails.currentPlan === planTypes.GROWTH) {
        notify.show(
          "You're on the Growth plan. Only the first " +
            signupsLimitToExport.GROWTH_LIMIT +
            " sign-ups will be exported. Upgrade to export all",
          "warning",
          3000
        );
      }
    }

    if (this.state.paywallLimitImposed) {
      if (this.state.user.billingDetails.currentPlan === planTypes.FREE) {
        notify.show(
          "You're on the Free plan. Only the first " +
            signupsLimitToExport.FREE_LIMIT +
            " sign-ups will be exported. Upgrade to export more",
          "warning",
          3000
        );
      }
    }
  };

  export = () => {
    ReactGA.event({
      category: "User",
      action: "Export to CSV"
    });

    this.showExportLimitAlert();
    api.exportData(this.state.waitlist._id);
  };

  import = () => {
    this.fileUploadRef.current.click();
  };

  onFileChange = (event) => {
    let file = event.target.files[0];

    api.importSignupsFromCSV(file, this.state.waitlist._id, (res) => {
      if (res.status === 200) {
        if (res.data.success) {
          notify.show(
            `${res.data.newEntries} signups imported successfully`,
            "success",
            NOTIFICATION_LENGTH
          );
          this.setState(
            {
              page: 0
            },
            () => {
              this.load();
            }
          );
          return;
        }

        notify.show(res.data.message, "error", NOTIFICATION_LENGTH);
      }
    });

    event.target.value = "";
  };

  downloadCsv = (data) => {
    var csv = "email\n";
    data.forEach(function (row) {
      csv += row;
      csv += "\n";
    });

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = "referlist_whitelisted_and_removed.csv";
    hiddenElement.click();
  };

  remove = () => {
    let { selectedSignups, signups } = this.state;

    if (selectedSignups.length !== signups.length) {
      notify.show("Whoops! Something went wrong", "error", 3000);
      return;
    }

    let i,
      signupsToRemove = [],
      emailsToRemove = [];
    for (i = 0; i < signups.length; i++) {
      if (selectedSignups[i]) {
        signupsToRemove.push(signups[i]._id);
        emailsToRemove.push(signups[i].email);
      }
    }

    api.removeFromList(signupsToRemove, (res) => {
      if (res.status === 200) {
        this.downloadCsv(emailsToRemove);

        notify.show("Removed from list", "custom", 3000, {
          background: "#4287f5",
          text: "#FFFFFF"
        });
      }
    });

    this.setState({ confirmRemoveSignups: false });
  };

  exportToMailchimp = () => {
    ReactGA.event({
      category: "User",
      action: "Export to Mailchimp"
    });

    api.exportToMailchimp(this.state.waitlist._id, (res) => {
      if (res.status === 200) {
        notify.show(
          "An export to our friends at Mailchimp was started. We'll email you when it's complete",
          "custom",
          3000,
          { background: "#4287f5", text: "#FFFFFF" }
        );
      }

      if (res.status === 400) {
        if (res.data === errorMessages.MAILCHIMP_NOT_CONFIGURED) {
          notify.show(
            "Whoops! You'll have to connect your Mailchimp account in the Setup page first",
            "error",
            3000
          );
        }
      }
    });
  };

  render() {
    const {
      signups,
      selectedSignups,
      page,
      rowsPerPage,
      totalCount,
      dataLoaded,
      user,
      paywallLimitImposed,
      referralCount,
      confirmRemoveSignups,
      joinedSort
    } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Container className={classes.parentContainer}>
          <Notifications />
          <Header />
          {dataLoaded ? (
            <Grid container direction="column" justify="space-around">
              <Grid
                item
                container
                justify="flex-end"
                style={{ marginBottom: 15 }}
              >
                {selectedSignups.every((val) => val === false) ? (
                  <div>
                    <Button
                      color="primary"
                      onClick={this.exportToMailchimp}
                      className={classes.saveBtnStyle}
                    >
                      Export to Mailchimp
                    </Button>
                    <Button
                      color="primary"
                      onClick={this.export}
                      className={classes.saveBtnStyle}
                    >
                      Export to CSV
                    </Button>

                    <Button
                      color="primary"
                      onClick={this.import}
                      className={classes.saveBtnStyle}
                    >
                      Import from CSV
                    </Button>

                    <input
                      id="myInput"
                      type="file"
                      ref={this.fileUploadRef}
                      style={{ display: "none" }}
                      accept=".csv"
                      onChange={this.onFileChange}
                    />
                  </div>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setState({ confirmRemoveSignups: true });
                    }}
                    className={classes.saveBtnStyle}
                  >
                    Remove from list
                  </Button>
                )}
              </Grid>
              <Grid
                item
                container
                direction="row"
                spacing={2}
                className={classes.dashboardStyle}
              >
                <Grid item xs={6} sm={6} md={2}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.countStyles}>
                      {totalCount.toLocaleString()}
                    </Typography>
                    <Typography
                      className={[classes.textStyles1, classes.textStyle].join(
                        " "
                      )}
                    >
                      Sign-ups
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.countStyles}>
                      {referralCount.toLocaleString()}
                    </Typography>
                    <Typography
                      className={[classes.textStyles, classes.textStyle].join(
                        " "
                      )}
                    >
                      Sign-ups via referral
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              {paywallLimitImposed &&
                user.billingDetails.currentPlan === planTypes.FREE && (
                  <Grid item container className={classes.marginTop20}>
                    <Paper
                      className={[classes.padding10, classes.paper].join(" ")}
                    >
                      <Typography
                        className={[classes.padding10, classes.textStyle].join(
                          " "
                        )}
                      >
                        You hit your {signupsLimitToExport.FREE_LIMIT} free
                        signups. Things are picking up. Congrats!
                      </Typography>
                      <Typography
                        className={[
                          classes.padding10,
                          classes.textStyle,
                          classes.upgradeTextStyle
                        ].join(" ")}
                      >
                        Don’t worry. Your users can still sign up uninterrupted
                        even after you’ve hit {signupsLimitToExport.FREE_LIMIT}{" "}
                        sign-ups. Upgrade to a paid plan to view and export more
                        emails.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        className={[
                          classes.saveBtnStyle,
                          classes.upgradeButtonStyle
                        ].join(" ")}
                        onClick={() => this.props.setComponent("billing")}
                      >
                        UPGRADE
                      </Button>
                    </Paper>
                  </Grid>
                )}
              {paywallLimitImposed &&
                user.billingDetails.currentPlan === planTypes.GROWTH && (
                  <Grid item container className={classes.marginTop20} md={12}>
                    <Paper
                      className={[classes.padding10, classes.paper].join(" ")}
                    >
                      <Typography
                        className={[classes.padding10, classes.textStyle].join(
                          " "
                        )}
                      >
                        You hit the {signupsLimitToExport.GROWTH_LIMIT} sign-ups
                        included in the Growth plan. It's time for the big
                        leagues
                      </Typography>
                      <Typography
                        className={[
                          classes.padding10,
                          classes.textStyle,
                          classes.upgradeTextStyle
                        ].join(" ")}
                      >
                        Don’t worry. Your users can still sign up uninterrupted
                        even after you’ve hit{" "}
                        {signupsLimitToExport.GROWTH_LIMIT} sign-ups. Upgrade to
                        the Pro plan to view and export unlimited emails.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        className={[
                          classes.saveBtnStyle,
                          classes.upgradeButtonStyle
                        ].join(" ")}
                        onClick={() => this.props.setComponent("billing")}
                      >
                        UPGRADE
                      </Button>
                    </Paper>
                  </Grid>
                )}

              <Paper
                className={[
                  classes.width100,
                  classes.marginTop20,
                  classes.overflowx,
                  classes.paper
                ].join(" ")}
              >
                {signups.length > 0 || paywallLimitImposed ? (
                  <Grid item>
                    <Table className={classes.width100}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headerStyle}>
                            <Checkbox
                              checked={selectedSignups.every(
                                (val) => val === true
                              )}
                              onChange={(event) => {
                                this.setState({
                                  selectedSignups: new Array(
                                    selectedSignups.length
                                  ).fill(event.target.checked)
                                });
                              }}
                              inputProps={{
                                "aria-label": "secondary checkbox"
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.headerStyle}>
                            Position
                          </TableCell>
                          <TableCell className={classes.headerStyle}>
                            Email
                          </TableCell>
                          <TableCell className={classes.headerStyle}>
                            Referral code
                          </TableCell>
                          <TableCell className={classes.headerStyle}>
                            <TableSortLabel
                              direction={joinedSort}
                              onClick={this.handleJoinedSort}
                            >
                              Joined
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.headerStyle}>
                            Email verified
                          </TableCell>
                          <TableCell className={classes.headerStyle}>
                            Verified referrals
                          </TableCell>
                          <TableCell className={classes.headerStyle}>
                            Referred by
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {signups.map((signup, index) => (
                          <TableRow key={signup._id}>
                            <TableCell className={classes.textStyle}>
                              <Checkbox
                                key={index}
                                checked={selectedSignups[index]}
                                onChange={(event) =>
                                  this.handleChecked(index, event)
                                }
                                inputProps={{
                                  "aria-label": "secondary checkbox"
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.textStyle}>
                              {joinedSort === ""
                                ? page * rowsPerPage + index + 1
                                : "asc"}
                            </TableCell>
                            <TableCell className={classes.textStyle}>
                              {signup.email}
                            </TableCell>
                            <TableCell className={classes.textStyle}>
                              {signup.referralCode}
                            </TableCell>
                            <TableCell className={classes.textStyle}>
                              {formatForDisplay(signup.signupDate)}
                            </TableCell>
                            <TableCell className={classes.textStyle}>
                              {signup.verified ? "Yes" : "No"}
                            </TableCell>
                            <TableCell className={classes.textStyle}>
                              {(signup.points - 1).toLocaleString()}
                            </TableCell>
                            <TableCell className={classes.textStyle}>
                              {signup.referredBy !== undefined &&
                              signup.referredBy !== null &&
                              signup.referredBy.length > 0
                                ? signup.referredBy[0].email
                                : "None"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[50, 100, 150]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      backIconButtonProps={{
                        "aria-label": "previous page"
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page"
                      }}
                    />
                  </Grid>
                ) : (
                  <Typography className={classes.padding10}>
                    No one has signed up for your waitlist yet
                  </Typography>
                )}
              </Paper>
            </Grid>
          ) : (
            <Paper
              className={[
                classes.width100,
                classes.marginTop20,
                classes.paper
              ].join(" ")}
            >
              <Typography className={classes.padding10}>
                Loading your sign-ups...
              </Typography>
            </Paper>
          )}
          <ConfirmDialog
            show={confirmRemoveSignups}
            title="Are you sure?"
            message="You will have to import these emails again once removed."
            onSuccess={this.remove}
            onCancel={() => {
              this.setState({ confirmRemoveSignups: false });
            }}
          />
        </Container>
      </Fragment>
    );
  }
}

Signups = withStyles(styles)(Signups);

export default Signups;
