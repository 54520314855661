import blueGrey from "@material-ui/core/colors/blueGrey";

const colors = {
  primary: "#2a87ff",
  secondary: "#1565c0",
  white: "#FFFFFF",
  black: "#000000",
  red: "#D32F2F",
  whitesmoke: "#f1f1f1",
  blizzardblue: "#9ee5f8",
  darkred: "#FF2F55",
  green: "#68BB59",
  orange: "#F5C718",
  secondaryColor: "#FF2F55",
  copyButton: "#9E9E9E",
  selectedPlan: "#E3F2FD",
  selectedPlanHover: "#FBFDFF",
  fontColor: "#9c9797",
  domainColor: "#1565c0",
  menuHighlight: blueGrey[700],
  menuSelected: "#F4F7FF",
  blue300:"#64B5F6",
  backgroundColor: "#fafafa",
};

export default colors;
