import React, { Component } from "react";
import Api from "../Services/Api";

const api = Api.create();

class MailRedirection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Loading..."
    };
  }
  componentDidMount() {
    const waitlistId = this.props.match.params.waitlistId;
    api.getWaitlistById(waitlistId, res => {
      if (res.status === 200) {
        this.props.history.push({ pathname: "/home" + res.data._id, state: { signup: true } });
      } else if (res.status === 401) {
        this.setState({ message: "You don't own this waitlist." });
      } else {
        this.setState({ message: "Hmmm... looks like you're lost"});
      }
    });
  }

  render() {
    let { message } = this.state;
    return (
      <div style={{ marginTop: 40, textAlign: "center", fontSize: 20 }}>
        {message}
      </div>
    );
  }
}

export default MailRedirection;
