const styles = theme => ({
  container: {
    height: "100vh",
    paddingLeft: 0
  },
  gridContainer: {
    height: "100vh",
    marginLeft: 0,
    paddingLeft: 0,
    marginRight: 0,
    paddingRight: 0
  },
  padding10: {
    padding: 10
  },
  marginTop10: {
    marginTop: 10
  },
  image: {
    width: "inherit",
    height: "inherit"
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: 50,
    height: 50
  },
  textAlignCenter: {
    textAlign: "center",
    wordBreak:"break-word"
  },
  createImage: {
    width: "100%",
    height: 240,
    cursor: "pointer"
  },
  width100: {
    width: "100%"
  },
  marginTop20: {
    marginTop: 20
  },
  paper: {
    width: "100%",
    height: "100%",
    cursor:"pointer",
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    "&:hover": {
      boxShadow: "0 2px 7px 0px rgba(0,0,0,.2)"
    }
  },
  queueImageDiv: {
    width: "100%",
    height: "100%",
    margin: "auto"
  },
  queueImage: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: 400,
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  logo: {
    width: "25%",
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  logoutBtn: {
    marginRight: 20,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      marginTop: 20
    }
  },
  backgroundImage: {
    background: "linear-gradient(180deg, #ffffff 396px, #2a87ff 396px)",
    minHeight:"100vh",
    height: "100%",
    backgroundSize: "100% 100%",
    backgroundRepeat: "repeat",
    [theme.breakpoints.down("xs")]: {
      background: "linear-gradient(180deg, #ffffff 205px, #2a87ff 205px)",
    },
    paddingBottom:20
  },
  marginLeft20: {
    marginLeft: "20%"
  },
  waitlistsMargin: {
    width:960,
    margin:"auto",
    [theme.breakpoints.down("sm")]: {
      width:600
    },
    [theme.breakpoints.down("xs")]: {
      width:"100%"
    }
  },
  waitlistsName: {
    marginTop: 20,
    marginLeft:"20%",
    fontSize: 20,
      [theme.breakpoints.down("xs")]: {
        marginLeft: "4%",
        marginTop: 12
      }
  },
  createText:{
    fontSize: 12,
    marginTop:"32%",
    color: "#989a9c"
  },
  plusIcon:{
    margin:"auto",
    display:"block",
    fontSize: 40,
    color: "#2887ff"
  },
  redirectText:{
    fontSize: 12,
    color: "#989a9c"
  },
  createWaitlistText: {
    fontSize: 18
  },
  height160:{
    height:160
  }
});
export default styles;
