import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import { Container, Grid, Paper, Typography, Button } from "@material-ui/core";
import Header from "../Components/Header";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "../Components/CheckoutForm";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Notifications, { notify } from "react-notify-toast";

import styles from "./styles/BillingStyle";
import API from "../Services/Api";
import ReactGA from "react-ga";
import { formatForDisplayDate } from "../Utils/TimeUtils";
import {
  planTypes,
  signupsLimitToExport,
} from "../Utils/Types";

const api = API.create();

class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      haveCardOnFile: false,
      currentPlan: "",
      needPaymentInfoDialogOpen: false,
      showPlanChangeConfirmationDialogOpen: false,
      selectedPlan: "",
      totalSignupCount: 0,
      requestedForDowngrade: false,
      expirationDate: null,
      nextPlan: "",
      discount: ""
    };
  }

  componentDidMount = () => {
    ReactGA.pageview('billing');
    this.load();
  };

  load = () => {
    api.getClientAccount(res => {
      if (res.status === 200) {
        this.setState(
          {
            haveCardOnFile: res.data.haveCardOnFile,
            payments: res.data.payments,
            currentPlan: res.data.currentPlan,
            totalSignupCount: res.data.totalSignupCount,
            nextPlan: res.data.nextPlan,
            discount: res.data.discount,
            expirationDate: res.data.expirationDate,
            requestedForDowngrade: res.data.requestedForDowngrade
          }
        );
      } else {
        notify.show(
          "We couldn't retrieve your billing details. Try again later or contact support@referlist.co",
          "error",
          3000
        );
      }
    });
  };

  updateCallback = () => {
    notify.show("Your card info was updated", "success", 3000);
    this.load();
  };

  onPlanSelected = selectedPlan => {
    ReactGA.event({
      category: 'User',
      action: 'Select plan ' + selectedPlan
    });

    let { currentPlan } = this.state;
    if (currentPlan === selectedPlan) return;

    if (this.state.haveCardOnFile) {
      this.setState({
        showPlanChangeConfirmationDialogOpen: true,
        selectedPlan: selectedPlan
      });
    } else {
      this.setState({ needPaymentInfoDialogOpen: true });
    }
  };

  handlePlanChangeConfirmation = () => {
    let { selectedPlan } = this.state;

    api.subscribetoplan(selectedPlan, res => {
      if (res.status === 200) {
        this.setState({ selectedPlan: "" });

        if (selectedPlan === planTypes.FREE) {
          notify.show(
            "You'll be downgraded to the Free plan once your current subscription ends",
            "info",
            3000
          );
        } else {
          this.setState({ currentPlan: selectedPlan, selectedPlan: "" });
          notify.show("Successfully changed your plan", "info", 3000);
        }
        this.load();
      } else if (res.status === 400) {
        notify.show(res.data, "error", 3000);
      }
    });
    this.setState({ showPlanChangeConfirmationDialogOpen: false });
  };

  render() {
    const { classes } = this.props;
    const {
      haveCardOnFile,
      currentPlan,
      needPaymentInfoDialogOpen,
      showPlanChangeConfirmationDialogOpen,
      selectedPlan,
      requestedForDowngrade,
      expirationDate,
      nextPlan,
      discount
    } = this.state;

    let STRIPE_API_KEY = "pk_live_azhK5VXdXjCspCLaFK6e25DG00DlEvlRay";
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log("Using test key");
      STRIPE_API_KEY = "pk_test_6NoOGbFYi4l3s0fEJIicQ8Of00cHLqO3IF";
    }

    return (
      <Fragment>
        <Container className={classes.container}>
          <Header />
          <Notifications />
          <Paper
            className={[
              classes.width100,
              classes.marginTop20,
              classes.paper
            ].join(" ")}
          >
            <Grid container direction="column" className={classes.padding20}>
              <Grid item className={classes.width100}>
                <StripeProvider apiKey={STRIPE_API_KEY}>
                  <Elements>
                    <CheckoutForm
                      haveCardOnFile={haveCardOnFile}
                      updateCallback={this.updateCallback}
                    />
                  </Elements>
                </StripeProvider>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            className={[
              classes.width100,
              classes.marginTop20,
              classes.paper
            ].join(" ")}
          >
            <Grid container direction="column" className={classes.padding20}>
              <Grid item>
                <Typography variant="subtitle2" className={classes.headerStyle}>
                  Plan
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant="subtitle2"
                  className={[
                    classes.marginTop10,
                    classes.textStyle,
                    classes.padding10
                  ].join(" ")}
                >
                  You are on the {currentPlan} plan
                  {requestedForDowngrade && (
                    <span style={{ fontFamily: "Open Sans" }}>
                      . You'll be downgraded to {nextPlan} on{" "}
                      {formatForDisplayDate(expirationDate)}
                    </span>
                  )}
                </Typography>
              </Grid>

              <Grid
                item
                container
                direction="row"
                className={classes.marginTop20}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={4}
                  md={4}
                  onClick={() => this.onPlanSelected(planTypes.FREE)}
                >
                  <Paper
                    className={
                      currentPlan === planTypes.FREE
                        ? classes.selected
                        : classes.unselected
                    }
                  >
                    <div style={{ padding: 20, textAlign: "center" }}>
                      <Typography style={{ marginBottom: 10 }}>
                        Free 😊
                      </Typography>
                      <Typography variant="h6">$0/month</Typography>
                    </div>{" "}
                    <div
                      className={[classes.padding20, classes.featuresText].join(
                        " "
                      )}
                    >
                      <Typography>
                        <ul>
                          <li>
                            View and export up to{" "}
                            {signupsLimitToExport.FREE_LIMIT} sign-ups
                          </li>
                        </ul>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={4}
                  md={4}
                  onClick={() => this.onPlanSelected(planTypes.GROWTH)}
                >
                  <Paper
                    className={
                      currentPlan === planTypes.GROWTH
                        ? classes.selected
                        : classes.unselected
                    }
                  >
                    <div style={{ padding: 20, textAlign: "center" }}>
                      <Typography style={{ marginBottom: 10 }}>
                        Growth 📈
                      </Typography>
                      <Typography variant="h6">{discount === "ycdeal" ? "30 days free and then $15/month on YC deal (normally $29)" : "$29/month"}</Typography>
                    </div>
                    <div
                      className={[classes.padding20, classes.featuresText].join(
                        " "
                      )}
                    >
                      <Typography>
                        <ul>
                          <li>
                            View and export up to{" "}
                            {signupsLimitToExport.GROWTH_LIMIT} sign-ups
                          </li>
                          <li>Customize look and feel of waitlist page</li>
                          <li>No "Powered by Referlist" watermark</li>
                        </ul>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={4}
                  md={4}
                  onClick={() => this.onPlanSelected(planTypes.PRO)}
                >
                  <Paper
                    className={
                      currentPlan === planTypes.PRO
                        ? classes.selected
                        : classes.unselected
                    }
                  >
                    <div style={{ padding: 20, textAlign: "center" }}>
                      <Typography style={{ marginBottom: 10 }}>
                        Pro 🔥
                      </Typography>
                      <Typography variant="h6">$59 + $0.01/signup/mo</Typography>
                    </div>
                    <div
                      className={[classes.padding20, classes.featuresText].join(
                        " "
                      )}
                    >
                      <Typography>
                        <ul>
                          <li>3,000 sign-ups included</li>
                          <li>$0.01/signup/mo after first 3,000</li>
                          <li>Customize look and feel of waitlist page</li>
                          <li>API access</li>
                          <li>Seed your waitlist so it's not empty at launch</li>
                          <li>No "Powered by Referlist" watermark</li>
                          <li>Contact for discount pricing over 3k</li>
                        </ul>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Dialog
            open={needPaymentInfoDialogOpen}
            onClose={() => this.setState({ needPaymentInfoDialogOpen: false })}
          >
            <DialogTitle>Add a card first</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className={classes.fontStyle}>
                  Please enter payment information to select a paid plan
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ needPaymentInfoDialogOpen: false })
                }
                variant="contained"
                color="secondary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={showPlanChangeConfirmationDialogOpen}
            onClose={() =>
              this.setState({
                showPlanChangeConfirmationDialogOpen: false,
                selectedPlan: ""
              })
            }
          >
            <DialogTitle>Switch to the {selectedPlan} plan</DialogTitle>
            <DialogContent>
              {selectedPlan === "Free" && (
                <Fragment>
                  <DialogContentText>
                    You can only export up to {signupsLimitToExport.FREE_LIMIT} sign-ups
                    with this plan. Are you sure you want to downgrade?
                  </DialogContentText>
                </Fragment>
              )}

              {selectedPlan === planTypes.GROWTH && (
                <Fragment>
                  <DialogContentText>
                    View and export up to {signupsLimitToExport.GROWTH_LIMIT} sign-ups for{" "}
                    {discount === "ycdeal" ? "30 days free and then " + "$29".strike() + "$15/month with the YC deal (normally $29)" : "$29/month"}
                  </DialogContentText>
                </Fragment>
              )}

              {selectedPlan === planTypes.PRO && (
                <Fragment>
                  <DialogContentText>
                    View and export up to 3,000 sign-ups for $59/month
                  </DialogContentText>
                </Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({
                    showPlanChangeConfirmationDialogOpen: false,
                    selectedPlan: ""
                  })
                }
              >
                Cancel
              </Button>
              <Button
                onClick={this.handlePlanChangeConfirmation}
                variant="contained"
                color="secondary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Billing);
