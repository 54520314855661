import Colors from "../../Themes/Colors";
import grey from "@material-ui/core/colors/grey";

const styles = (theme) => ({
  container: {
    marginBottom: 30
  },
  marginTop10: {
    marginTop: 10
  },
  marginTop20: {
    marginTop: 20
  },
  marginTop30: {
    marginTop: 30
  },
  marginBottom30: {
    marginBottom: 30
  },
  width100: {
    width: "100%"
  },
  padding20: {
    padding: 20
  },
  padding10: {
    padding: 10
  },
  width80: {
    width: "80%"
  },
  width35: {
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.up("sm")]: {
      width: "35%"
    }
  },
  textStyle: {
    color: "#807b7b",
    fontSize: 16,
    paddingTop: 5
  },
  headerStyle: {
    color: Colors.fontColor,
    fontSize: 16,
    paddingLeft: 10
  },
  setupGriditemStyle: {
    display: "flex",
    marginTop: 15
  },
  saveBtnStyle: {
    borderRadius: "2px",
    width: "94px",
    height: "36px",
    color: Colors.white
  },
  previewBtnStyle: {
    borderRadius: "2px",
    width: "94px",
    height: "36px",
    color: Colors.white,
    marginRight: 10
  },
  displayNoneStyle: {
    display: "none"
  },
  paper: {
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)"
  },
  width25: {
    width: "25%"
  },
  button: {
    marginLeft: 10
  },
  logoImage: {
    width: 100,
    height: 100
  },
  docsButton: {
    marginLeft: 10,
    color: Colors.white,
    marginBottom: 10,
    marginTop: 10
  },
  connectBtnStyle: {
    borderRadius: "2px",
    color: Colors.white,
    marginLeft: 10
  },
  margin10: {
    margin: 10
  },
  proOnlyStyle: {
    color: Colors.primary,
    fontSize: 12,
    fontWeight: 900
  },
  copyButton: {
    marginTop: 45,
    marginLeft: 25
  },
  colorPickerStyles: {
    borderWidth: 0,
    width: 15,
    height: 20,
    cursor: "pointer",
    paddingLeft: 10,
    paddingTop: 5
  },
  color: {
    width: 15,
    height: 15,
    borderRadius: "2px",
    cursor: "pointer",
    margin: 9,
    background: "rgb(0,0,0,0)"
  },
  codeStyle: {
    backgroundColor: grey[100],
    borderRadius: 10
  },
  codeFont: {
    fontFamily: "Inconsolata"
  },
  marginLeft10: {
    marginLeft: 10
  },
  resetButton: {
    paddingRight: 10,
    color: Colors.secondary,
    paddingTop: 1
  },
  sectionHeader: {
    marginTop: 40,
    fontSize: 18,
    color: Colors.blue300
  },
  lettersCountStyle: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "80%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10
  },
  letterCountAdjustment: {
    marginBottom: 0,
    paddingBottom: 0
  },
  center: {
    marginTop: 40,
    margin: "auto",
    display: "block"
  },
  apiKey: {
    backgroundColor: grey[100],
    color: Colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    fontFamily: "Inconsolata",
    width: "100%"
  },
  overflowHidden: {
    overflow: "hidden",
    wordBreak: "break-word",
    textOverflow: "ellipsis",
    cursor: "pointer",
    userSelect: "all",
    padding: 10,
    "&:hover": {
      backgroundColor: grey[50],
      borderRadius: 6
    }
  }
});

export default styles;
