import { parseDomain } from "parse-domain";

export function isValidEmail(email) {
  const regexp = /\S+@\S+\.\S+/;
  return regexp.test(email);
}

export function isValidURL(url) {
  const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9.]{2,256}\.[a-z]{2,4}$/gim;
  return regex.test(url);
}

export function normalizeHost(host, domainFromDNS) {
  const { domain, topLevelDomains } = parseDomain(domainFromDNS);
  if (!host) {
    return host;
  }

  return host.replace("." + domain + "." + topLevelDomains, "");
}

export function getEmbeddedScript(domainName) {
  return `<!-- Begin referlist signup form -->
  <style>
    .referlistcontainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    #referlistemail {
      width: 250px;
      margin-right: 10px;
      height: 45px;
      border-radius: 32px;
      border-color: black;
      font-size: 16px;
      padding-left: 10px;
    }
    #referlistbutton {
      width: 150px;
      height: 45px;
      border: none;
      border-radius: 32px;
      font-size: 16px;
      background-color: black;
      color: white;
    }
    .referlistinnercontainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: 60px;
      padding-top: 60px;
    }
  </style>
  
  <form id="referlistform" class="referlistcontainer">
    <div class="referlistinnercontainer">
      <input
        type="text"
        id="referlistemail"
        placeholder="email@yourcompany.com"
      />
      <input type="button" id="referlistbutton" value="Join waitlist" />
    </div>
  </form>
  
  <script src="https://referlist.co/resources/referlist.js"
    type="text/javascript"></script>
  <script>
    document.addEventListener("DOMContentLoaded", function(event) {
      window.referlist.initialize({ domain: '${domainName}' });
    });
  </script>
  <!-- End referlist signup form -->`;
}
