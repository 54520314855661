import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import ReactGA from "react-ga";
import styles from "./styles/InstallStyles";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Tooltip,
  IconButton
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import colors from "../Themes/Colors";
import FileCopy from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Notifications, { notify } from "react-notify-toast";

import Header from "../Components/Header";

import { getEmbeddedScript } from "../Utils/UserUtils";

class Install extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitlist:
        this.props.waitlist !== undefined && this.props.waitlist !== null
          ? this.props.waitlist
          : null,
      embeddedscript: this.props.waitlist
        ? getEmbeddedScript(this.props.waitlist.domain)
        : ""
    };
  }

  componentDidMount() {
    ReactGA.pageview("install");
    if (this.props.setupSaved) notify.show("Setup saved", "success", 3000);
  }

  openIntegrationDocs = () => {
    ReactGA.event({
      category: "User",
      action: "Open documentation"
    });
    window.open("https://docs.referlist.co/");
  };

  onCopy = () => {
    notify.show("Copied", "success", 3000);
  };

  render() {
    const { embeddedscript } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <Container className={classes.container}>
          <Notifications />
          <Header title="Install" />
          <Grid
            item
            className={[classes.width100, classes.marginTop20].join(" ")}
          >
            <Paper
              className={[
                classes.marginBottom30,
                classes.marginTop10,
                classes.width100,
                classes.paper
              ].join(" ")}
            >
              <Grid
                container
                className={[classes.padding20, classes.width100].join(" ")}
              >
                <Grid item className={classes.width100}>
                  <Typography
                    variant="subtitle2"
                    className={classes.headerStyle}
                  >
                    Your waitlist is ready to go!
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    className={[
                      classes.marginTop10,
                      classes.textStyle,
                      classes.padding10
                    ].join(" ")}
                  >
                    Now that you’ve created your waitlist, you need to install
                    Referlist on your site to start accepting sign-ups
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    className={[
                      classes.marginTop10,
                      classes.textStyle,
                      classes.padding10
                    ].join(" ")}
                  >
                    Check out{" "}
                    <a
                      href="https://docs.referlist.co"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: colors.secondary }}
                    >
                      docs.referlist.co
                    </a>{" "}
                    for detailed instructions on how to install Referlist on
                    your site
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    className={[classes.textStyle, classes.padding10].join(" ")}
                  >
                    <span style={{ fontFamily: "Open Sans", fontWeight: 600 }}>
                      Option 1
                    </span>{" "}
                    Copy and paste the following code in your HTML page or in an
                    embedded code block for a website builder
                  </Typography>

                  <Grid item container direction="row">
                    <Grid item xs={5} className={classes.marginLeft10}>
                      <TextField
                        id="outlined-dense"
                        className={[
                          classes.marginTop10,
                          classes.padding10,
                          clsx(classes.textField, classes.dense),
                          classes.codeStyle
                        ].join(" ")}
                        value={embeddedscript}
                        multiline={true}
                        rows={10}
                        rowsMax={10}
                        contentEditable={false}
                        onFocus={this.handleFocus}
                        margin="dense"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.codeFont
                          }
                        }}
                        style={{
                          display: embeddedscript === "" ? "none" : "block"
                        }}
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <CopyToClipboard
                        onCopy={this.onCopy}
                        text={this.state.embeddedscript}
                        className={classes.copyButton}
                      >
                        <Tooltip title="Copy script">
                          <IconButton className={classes.copyButton}>
                            <FileCopy />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    </Grid>
                  </Grid>

                  <Typography
                    variant="subtitle2"
                    className={[classes.textStyle, classes.padding10].join(" ")}
                  >
                    <span style={{ fontFamily: "Open Sans", fontWeight: 600 }}>
                      Option 2
                    </span>{" "}
                    Install Referlist in your React app via{" "}
                    <a
                      href="https://www.npmjs.com/package/referlist"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontFamily: "Open Sans",
                        color: colors.secondary
                      }}
                    >
                      npm
                    </a>
                  </Typography>

                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    className={classes.docsButton}
                    onClick={this.openIntegrationDocs}
                  >
                    GO TO INSTALLATION DOCS
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Install);
