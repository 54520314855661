import React, { Component, Suspense } from "react";
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Container
} from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import API from "../Services/Api";
import queryString from "query-string";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withStyles } from "@material-ui/styles";
import Notifications, { notify } from "react-notify-toast";
import Page404 from "./Page404";
import { isValidEmail } from "../Utils/UserUtils";
import { planTypes } from "../Utils/Types";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from "react-share";
import AnimatedNumber from "react-animated-number";
import styles from "./styles/JoinedWaitlistStyle";
import colors from "../Themes/Colors";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import * as animationData from "../Images/confetti.json";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

const api = API.create();

class JoinedWaitlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      referralLink: "",
      referralSource: null,
      referredEmailsAnonymized: [],
      referredCount: 0,
      position: null,
      waitlist: null,
      waitlistDomain: "",
      error: false,
      errorMessage: "",
      currentPlan: "",
      fontColor: colors.black,
      backgroundColor: colors.white,
      animationIsStopped: false,
      animationIsPaused: false,
      antiSpamEnabled: false,
      isHumanVerificationDone: true,
    };
  }

   componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({ email: decodeURIComponent(values.email) });
    this.setState({ referralSource: values.ref });

    if (!isValidEmail(values.email)) {
      this.setState({
        error: true,
        errorMessage: "That doesn't seem to be a valid email"
      });
      return;
    }

    const waitlistDomain = this.props.match.params.domain;

    this.setState({ waitlistDomain: waitlistDomain });

    /**
     * Fetch the waitlist confiigurations, to decide if
     * antiSpamEnabled is Enabled: Show recaptcha to user, 
     *                             after successful verification, 
     *                             show success screen
     * antiSpamEnabled is Disabled: Directly show screen screen
     */
    api.getWaitlistConfigByDomainName(waitlistDomain, (res)=>{
      if (res.status === 200) {
        this.setState({antiSpamEnabled: res.data.antiSpamEnabled});
        if (!this.state.antiSpamEnabled) {
          this.processWaitlist();
        }
      } else {
        this.setState({
          error: true,
          errorMessage: "Hmmm... looks like you're lost"
        });
      }
    });
  }

  onCopy = () => {
    notify.show("Copied link. Share it with your friends", "success", 3000);
  };

  handleFocus = (event) => event.target.select();

  goBack = () => {
    let { referralLink } = this.state;
    window.location.replace(referralLink);
  };

  onExpired = () =>{
    window.history.back();
  }

  formatNumber = (number) => {
    return number.toLocaleString();
  };

  processWaitlist = () => {
    api.addToWaitlist(this.state.email, this.state.referralSource, this.state.waitlistDomain, (res) => {
      if (res.status === 200) {
        var url = res.data.waitlist.redirectUrl;
        var constructedLink = url + "?ref=" + res.data.referralCode;

        this.setState({
          referralLink: constructedLink,
          referredEmailsAnonymized: res.data.referredEmailsAnonymized,
          referredCount: res.data.referredCount,
          waitlist: res.data.waitlist,
          currentPlan: res.data.currentPlan,
          fontColor:
            res.data.waitlist.fontColor !== ""
              ? res.data.waitlist.fontColor
              : colors.black,
          backgroundColor:
            res.data.waitlist.backgroundColor !== ""
              ? res.data.waitlist.backgroundColor
              : colors.white
        });
      } else if (res.status === 400) {
        // Check for 400 and 404 for whitelist not found, errors
        this.setState({
          error: true,
          errorMessage: "Hmmm... looks like you're lost"
        });
      }
    });

    api.position(this.state.email, this.state.waitlistDomain, (res) => {
      if (res.status === 200) {
        this.setState({
          position: res.data.position
        });
      }
    });
  }

  onCaptchaVerified = () => { 
    this.setState({isHumanVerificationDone: false });
    this.processWaitlist();
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    let {
      waitlist,
      referralLink,
      position,
      errorMessage,
      currentPlan,
      fontColor,
      backgroundColor,
      referredEmailsAnonymized,
      referredCount,
      antiSpamEnabled,
      isHumanVerificationDone
    } = this.state;

    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    if (this.state.error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    let unmentionedReferrals = referredCount - referredEmailsAnonymized.length;

    /**
     * if Anti Spam config is Enabled + Already not verified: show recaptcha screen
     * else show success screen
     */
    if(antiSpamEnabled && isHumanVerificationDone){
      return (
        <div
            className={classes.container}
            style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}
          >
        <ReCAPTCHA
          sitekey="6LepgBopAAAAACwc3b1iu2-old22VeLw2qAJXd7F"
          size="normal"
          onExpired={this.onExpired}
          onChange={this.onCaptchaVerified}
        />
        </div>
      )
    } 
    return (
      waitlist  && (
        <Suspense fallback="loading">
          <div
            className={classes.container}
            style={{ backgroundColor: backgroundColor, color: fontColor }}
          >
            <Helmet>
              <title>{waitlist.companyName} - Referlist</title>
            </Helmet>
            <div className={classes.main}>
              <Container>
                <Notifications />
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      {waitlist.logo !== "" ? (
                        <img
                          src={waitlist.logo}
                          className={classes.avatar}
                          alt="logo"
                        />
                      ) : (
                        <div className={classes.avatar}></div>
                      )}
                      <Lottie height={200} options={defaultOptions} />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        {waitlist.title ? waitlist.title : t("title")}
                      </Typography>
                    </Grid>

                    {position !== null && (
                      <Grid item xs={12}>
                        {position === 0 && (
                          <Typography variant="body1" gutterBottom>
                            {t("firstInLine")}
                          </Typography>
                        )}
                        {position === 1 && (
                          <Typography variant="body1" gutterBottom>
                            {t("onePersonAhead")}
                          </Typography>
                        )}
                        {position !== 0 && position !== 1 && (
                          <Typography variant="body1">
                            {t("beforePositionInLine")}{" "}
                            <AnimatedNumber
                              component="text"
                              value={position}
                              style={{
                                transition: "0.8s ease-out",
                                fontSize: 22,
                                transitionProperty:
                                  "background-color, color, opacity"
                              }}
                              formatValue={this.formatNumber}
                              duration={2000}
                              stepPrecision={0}
                            />{" "}
                            {t("afterPositionInLine")}
                          </Typography>
                        )}
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        className={classes.referralContainer}
                      >
                        <Grid item sm={11} xs={10}>
                          <Typography
                            variant="body1"
                            gutterBottom
                            className={classes.referralLink}
                          >
                            {referralLink}
                          </Typography>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                          <CopyToClipboard
                            onCopy={this.onCopy}
                            text={this.state.referralLink}
                          >
                            <Tooltip title="Copy link">
                              <IconButton
                                className={classes.copyButton}
                                style={{ color: fontColor }}
                              >
                                <FileCopy />
                              </IconButton>
                            </Tooltip>
                          </CopyToClipboard>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      justify="center"
                      spacing={1}
                    >
                      <Grid item>
                        <TwitterShareButton
                          className={classes.shareButtonStyle}
                          title={
                            waitlist.shareMessage
                              ? waitlist.shareMessage
                              : "Just signed up for " +
                                waitlist.companyName +
                                "! Check it out at "
                          }
                          url={referralLink}
                        >
                          <TwitterIcon round={true} size={40} />
                        </TwitterShareButton>
                      </Grid>
                      <Grid item>
                        <FacebookShareButton
                          className={classes.shareButtonStyle}
                          quote={
                            waitlist.shareMessage
                              ? waitlist.shareMessage
                              : "Just signed up for " +
                                waitlist.companyName +
                                "! Check it out at "
                          }
                          url={referralLink}
                        >
                          {" "}
                          <FacebookIcon round={true} size={40} />
                        </FacebookShareButton>
                      </Grid>
                      <Grid item>
                        <LinkedinShareButton
                          url={referralLink}
                          className={classes.shareButtonStyle}
                        >
                          <LinkedinIcon round={true} size={40} />
                        </LinkedinShareButton>
                      </Grid>
                      <Grid item>
                        <WhatsappShareButton
                          className={classes.shareButtonStyle}
                          title={
                            waitlist.shareMessage
                              ? waitlist.shareMessage
                              : "Just signed up for " +
                                waitlist.companyName +
                                "! Check it out at "
                          }
                          url={referralLink}
                        >
                          <WhatsappIcon round={true} size={40} />
                        </WhatsappShareButton>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.margin10}>
                      <Typography variant="h6" gutterBottom>
                        {waitlist.subtitle ? waitlist.subtitle : t("subtitle")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.bodyText}
                      >
                        {waitlist.body ? waitlist.body : t("body")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Typography variant="body1" className={classes.margin10}>
                        {referredCount > 0 &&
                          t("beforeReferredCount") +
                            " " +
                            referredCount.toLocaleString() +
                            " " +
                            (referredCount === 1
                              ? t("afterReferredCountSingular")
                              : t("afterReferredCountPlural"))}
                      </Typography>
                    </Grid>

                    <Grid item container direction="column" xs={12}>
                      {referredEmailsAnonymized &&
                        referredEmailsAnonymized.length > 0 &&
                        referredEmailsAnonymized.map((email) => (
                          <Grid item>
                            <Typography
                              variant="body1"
                              className={classes.margin10}
                            >
                              {email}
                            </Typography>
                          </Grid>
                        ))}
                      {unmentionedReferrals > 0 && <Typography variant="body1" className={classes.margin10}>
                        and {unmentionedReferrals}{" "}
                        more
                      </Typography>}
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Button
                        variant="outlined"
                        onClick={this.goBack}
                        className={[
                          classes.margin20,
                          classes.gobackButtonStyle
                        ].join(" ")}
                        style={{ color: fontColor }}
                      >
                        {t("back")}
                      </Button>
                    </Grid>
                    {currentPlan === planTypes.FREE && (
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          className={classes.margin50}
                        >
                          <a
                            href="https://referlist.co"
                            rel="noopener noreferrer"
                          >
                            {t("poweredByReferlist")}
                          </a>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </Suspense>
      )
    );
  }
}

export default withTranslation()(withStyles(styles)(JoinedWaitlist));
