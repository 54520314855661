import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ProtectedRoute from "./Components/ProtectedRoute";
import Login from "./Containers/Login";
import Home from "./Containers/Home";
import Setup from "./Containers/Setup";
import Signups from "./Containers/Signups";
import JoinedWaitlist from "./Containers/JoinedWaitlist";
import EmailVerified from "./Containers/EmailVerified";
import MailchimpCallback from "./Components/MailchimpCallback";
import Waitlists from "./Containers/Waitlists";
import MailRedirection from "./Components/MailRedirection";
import firebase from "firebase/app";
import colors from "./Themes/Colors";
import FullStory from "react-fullstory";
import ReactGA from "react-ga";

let GOOGLE_ANALYTICS_ID = "UA-134454960-8";
let FULLSTORY_ID = "R3EBK"; //"KJA2P";//"Q6DBJ";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  GOOGLE_ANALYTICS_ID = "DEV_CODE";
  FULLSTORY_ID = "DEV_CODE";
}

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(",")
  },
  palette: {
    secondary: {
      main: colors.secondary
    },
    primary: {
      main: colors.primary
    }
  }
});

class Root extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      firebaseListenerRegistered: false
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          firebaseListenerRegistered: true,
          authenticated: true
        });
      } else {
        this.setState({
          firebaseListenerRegistered: true,
          authenticated: false
        });
      }
    });
  }

  render() {
    if (!this.state.firebaseListenerRegistered) {
      return <div></div>;
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <FullStory org={FULLSTORY_ID} />
          <BrowserRouter>
            <Switch>
              <Route
                path="/ycdeal"
                component={() => {
                  window.location.href = "https://forms.gle/LJuec8w7PB3sE9tH8";
                  return null;
                }}
              />
              <Route exact path="/login" component={Login} />
              <Route
                path="/joinwaitlist/:domain"
                exact
                component={JoinedWaitlist}
              />
              <ProtectedRoute path="/home/:waitlistId" component={Home} />
              <ProtectedRoute path="/setup" exact component={Setup} />
              <ProtectedRoute path="/signups" exact component={Signups} />
              <ProtectedRoute
                path="/mailchimpcallback"
                exact
                component={MailchimpCallback}
              />
              <ProtectedRoute path="/waitlists" exact component={Waitlists} />
              <ProtectedRoute
                path="/mail/:waitlistId"
                exact
                component={MailRedirection}
              />
              <Route exact path="/verify" component={EmailVerified} />
            </Switch>
          </BrowserRouter>
        </MuiThemeProvider>
      );
    }
  }
}

export default Root;
