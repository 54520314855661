import Colors from "../../Themes/Colors";
import grey from "@material-ui/core/colors/grey";

const styles = {
  container:{
    marginBottom:30
  },
  marginTop10: {
    marginTop: 10
  },
  marginTop20: {
    marginTop: 20
  },
  marginBottom30: {
    marginBottom: 30
  },
  width100: {
    width: "100%"
  },
  padding20: {
    padding: 20
  },
  padding10: {
    padding: 10
  },
  textStyle: {
    color: "#807b7b",
    fontSize: 16,
    paddingTop: 5
  },
  headerStyle: {
    color: Colors.fontColor,
    fontSize: 16,
    paddingLeft: 10
  },
  paper: {
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)"
  },
  docsButton: {
    marginLeft: 10,
    color: Colors.white,
    marginBottom: 10,
    marginTop: 10
  },
  copyButton: {
    marginTop: 95,
    marginLeft: 25
  },
  codeStyle: {
    backgroundColor: grey[100],
    borderRadius: 10
  },
  codeFont: {
    fontFamily: "Inconsolata"
  },
  marginLeft10: {
    marginLeft: 10
  }
}

export default styles;
