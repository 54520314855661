import grey from "@material-ui/core/colors/grey";

const useStyles = theme => ({
  cardFormContainer: {
    display: "flex",
    flexDirection: "column"
  },
  cardOnFile: {
    marginTop: 10,
    marginBottom: 10,
    color: grey[700]
  },
  cardForm: {
    [theme.breakpoints.down("md")]: {
      padding: 20,
      marginTop: 10,
      marginBottom: 20
    },
    [theme.breakpoints.up("sm")]: {
      width: 400,
      padding: 20,
      marginTop: 10,
      marginBottom: 20
    }
  },
  saveButton: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      paddingLeft: 20,
      paddingRight: 20,
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center"
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      paddingLeft: 20,
      paddingRight: 20,
      width: 400,
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center"
    }
  },
  headerStyle: {
    color: "#9c9797",
    fontSize: 16
  },
  width100: {
    width: "100%"
  }
});

export default useStyles;
