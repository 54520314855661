import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import ReactGA from "react-ga";
import Setup from "./Setup";
import Signups from "./Signups";
import Billing from "./Billing";
import Install from "./Install";
import styles from "./styles/HomeStyle";
import Images from "../Themes/Images";
import { ChevronLeft } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drift from "react-driftjs";
import API from "../Services/Api";

const api = API.create();

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentToLoad: null,
      waitlist: null,
      showNavOptions: false,
      valueChanged: false,
      showUnsavedChangesDialog: false,
      nextComponentSelected: "",
      setupSaved: false
    };
  }

  componentDidMount() {
    ReactGA.pageview("home");

    const waitlistId = this.props.match.params.waitlistId;

    if (waitlistId === "new") {
      this.setState({
        componentToLoad: "setup",
        showNavOptions: false
      });
    } else {
      this.load(waitlistId);
      this.setState({
        showNavOptions: true
      });
    }
  }

  load = (waitlistId) => {
    var { componentToLoad } = this.state;
    api.getWaitlistFromId(waitlistId, (res) => {
      this.setState({
        waitlist: res.data
      });

      if (this.props.match.params.waitlistId === "new") {
        this.props.history.push("/home/" + waitlistId);
        this.setState({ componentToLoad: "install", valueChanged: false, showNavOptions: true });
      } else {
        if (!componentToLoad) {
          this.setState({ componentToLoad: "signup", valueChanged: false });
        }
      }
    });
  };

  setComponent = (component) => {
    let { valueChanged } = this.state;
    if (valueChanged) {
      // show popup to save the setup details
      this.setState({
        showUnsavedChangesDialog: true,
        nextComponentSelected: component
      });
    } else {
      this.setState({
        componentToLoad: component,
        nextComponentSelected: "",
        setupSaved: false
      });
    }
  };

  showBillingPage = () => {
    this.setState(
      {
        valueChanged: false,
        nextComponentSelected: ""
      },
      () => {
        this.setComponent("billing");
      }
    );
  };

  showInstallPage = () => {
    this.setState(
      {
        valueChanged: false,
        nextComponentSelected: "",
        setupSaved: true
      },
      () => {
        this.setState({
          componentToLoad: "install",
          nextComponentSelected: ""
        });
      }
    );
  };

  enableSideNavigationOptions = () => {
    this.setState({
      showNavOptions: true,
      valueChanged: false,
      showUnsavedChangesDialog: false,
      nextComponent: ""
    });
  };

  setupValueChanged = () => {
    this.setState({ valueChanged: true });
  };

  resetSetupValueChanged = () => {
    this.setState({
      valueChanged: false,
      nextComponentSelected: ""
    });
  };

  hasUnSavedChanges = () => {
    return this.state.valueChanged;
  };

  discardSetupChanges = () => {
    let nextComponent = this.state.nextComponentSelected;
    this.setState(
      {
        valueChanged: false,
        showUnsavedChangesDialog: false,
        nextComponentSelected: ""
      },
      () => {
        this.setComponent(nextComponent);
      }
    );
  };

  hideUnSavedChangesDialog = () => {
    this.setState({
      showUnsavedChangesDialog: false,
      nextComponentSelected: ""
    });
  };

  setWaitlist = (waitlist) => {
    this.setState({ waitlist: waitlist });
  };

  render() {
    const {
      componentToLoad,
      waitlist,
      showNavOptions,
      showUnsavedChangesDialog,
      setupSaved
    } = this.state;

    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          className={classes.gridContainer}
        >
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className={classes.leftMenu}
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
            >
              <span
                className={classes.backButton}
                onClick={() => this.props.history.push("/waitlists")}
              >
                <ChevronLeft className={classes.backArrow} />
                <img
                  src={Images.headerImage}
                  alt="product logo"
                  className={classes.logo}
                ></img>
              </span>
              <Typography className={classes.domain}>
                {waitlist !== undefined && waitlist !== null && waitlist.domain}
              </Typography>
              <div className={classes.componentsNameDiv}>
                <div
                  onClick={() => this.setComponent("setup")}
                  className={
                    componentToLoad === "setup"
                      ? classes.selectedComponentStyle
                      : classes.unselectedComponentStyle
                  }
                >
                  <Typography
                    className={
                      componentToLoad === "setup"
                        ? classes.selectedComponentText
                        : classes.unselectedComponentText
                    }
                  >
                    Setup
                  </Typography>
                </div>

                <div
                  onClick={() => this.setComponent("install")}
                  className={
                    componentToLoad === "install"
                      ? classes.selectedComponentStyle
                      : classes.unselectedComponentStyle
                  }
                  style={{ display: showNavOptions ? "flex" : "none" }}
                >
                  <Typography
                    className={
                      componentToLoad === "install"
                        ? classes.selectedComponentText
                        : classes.unselectedComponentText
                    }
                  >
                    Install
                  </Typography>
                </div>

                <div
                  onClick={() => this.setComponent("signup")}
                  className={
                    componentToLoad === "signup"
                      ? classes.selectedComponentStyle
                      : classes.unselectedComponentStyle
                  }
                  style={{ display: showNavOptions ? "flex" : "none" }}
                >
                  <Typography
                    className={
                      componentToLoad === "signup"
                        ? classes.selectedComponentText
                        : classes.unselectedComponentText
                    }
                  >
                    Signups
                  </Typography>
                </div>

                <div
                  onClick={() => this.setComponent("billing")}
                  className={
                    componentToLoad === "billing"
                      ? classes.selectedComponentStyle
                      : classes.unselectedComponentStyle
                  }
                  style={{ display: showNavOptions ? "flex" : "none" }}
                >
                  <Typography
                    className={
                      componentToLoad === "billing"
                        ? classes.selectedComponentText
                        : classes.unselectedComponentText
                    }
                  >
                    Billing
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            className={classes.componentHolder}
          >
            {componentToLoad !== "" ? (
              componentToLoad === "setup" ? (
                <Setup
                  waitlist={waitlist}
                  showBillingPage={this.showBillingPage}
                  enableSideNavigationOptions={this.enableSideNavigationOptions}
                  resetSetupValueChanged={this.resetSetupValueChanged}
                  setupValueChanged={this.setupValueChanged}
                  updateWaitlist={this.setWaitlist}
                  hasUnSavedChanges={this.state.valueChanged}
                  showInstallPage={this.showInstallPage}
                  reload={this.load}
                />
              ) : componentToLoad === "signup" ? (
                <Signups waitlist={waitlist} setComponent={this.setComponent} />
              ) : componentToLoad === "billing" ? (
                <Billing waitlist={waitlist} />
              ) : componentToLoad === "install" ? (
                <Install waitlist={waitlist} setupSaved={setupSaved} />
              ) : (
                <div></div>
              )
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Dialog
          open={showUnsavedChangesDialog}
          onClose={this.hideUnSavedChangesDialog}
        >
          <DialogTitle>Save changes?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have unsaved changes. Please save or discard them before
              exiting setup.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "12px 24px" }}>
            <Button onClick={this.hideUnSavedChangesDialog}>
              Go back and save
            </Button>
            <Button
              onClick={this.discardSetupChanges}
              variant="contained"
              color="secondary"
              autoFocus
            >
              Discard
            </Button>
          </DialogActions>
        </Dialog>
        <Drift appId="rbfna89z24if" />
      </div>
    );
  }
}

Home = withStyles(styles)(Home);

export default Home;
