import Colors from "../../Themes/Colors";
import grey from '@material-ui/core/colors/grey';

const useStyles = {
  "@global": {
    body: {
      backgroundColor: Colors.white
    }
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom:30
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)"
  },
  notice: {
    marginTop: 10,
    marginBottom: 10,
    color: grey[700]
  },
  payments: {
    marginTop: 20,
    width: 440
  },
  width100: {
    width: "100%",
    height: "100%"
  },
  marginTop10: {
    marginTop: 10
  },
  unselected: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    "&:hover": {
      backgroundColor: Colors.selectedPlanHover,
      cursor: "pointer"
    }
  },
  selected: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,0)",
    backgroundColor: Colors.selectedPlan,
    "&:hover": {
      cursor: "pointer"
    }
  },
  headerStyle: {
    color: "#9c9797",
    fontSize: 16,
    paddingLeft: 10
  },
  textStyle: {
    color: "#807b7b",
    fontSize: 16,
    paddingTop: 5
  },
  marginTop20: {
    marginTop: 20
  },
  padding10: {
    padding: 10
  },
  padding20: {
    padding: 20
  },
  fontStyle: {
    color: Colors.fontColor
  },
  featuresText: {
    fontSize: 18,
    color: grey[700]
  },
  subtitle: {
    marginTop: 10, 
    marginBottom:10
  }
};

export default useStyles;
