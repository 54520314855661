import React, { Component } from "react";
import { Container, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import API from "../Services/Api";
import queryString from "query-string";

import styles from "./styles/EmailVerifiedStyle";

const api = API.create();

class EmailVerified extends Component {
  state = {
    message: null
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    if (values.token) {
      api.verifyEmail(values.token, (response) => {
        if (response.status === 200) {
          if (response.data.type === "already-verified") {
            this.setState({
              message:
                "Your email, " + response.data.email + ", is already verified"
            });
          } else {
            this.setState({
              message:
                "Your email, " +
                response.data.email +
                ", has been successfully verified"
            });
          }

          setTimeout(() => {
            this.props.history.push("/joinwaitlist/" + response.data.domain + "?email=" + encodeURIComponent(response.data.email));
          }, 1000);
        } else {
          this.setState({
            message: "Something went wrong. We couldn't verify your email"
          });
        }
      });
    } else {
      this.setState({
        message: "Something went wrong. We couldn't verify your email"
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.main}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            {this.state.message}
          </Typography>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(EmailVerified);
