import React, { Component } from "react";
import queryString from "query-string";
import Api from "../Services/Api";

const api = Api.create();

class MailchimpCallback extends Component {
  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const code = params.code;
    const waitlistId = params.waitlistId;

    api.getMailchimpAccessToken(code, waitlistId, res => {
      if (res.status === 200) {
        this.props.history.push({ pathname: "/waitlists" });
      }
    });
  }

  render() {
    return <div>Connected to Mailchimp! Redirecting...</div>;
  }
}

export default MailchimpCallback;