import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Select,
  InputBase,
  IconButton,
  Popover,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Switch
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import API from "../Services/Api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import RefreshIcon from "@material-ui/icons/Refresh";
import FileCopy from "@material-ui/icons/FileCopy";
import ReactGA from "react-ga";
import { uploadImage } from "../Utils/Storage";
import Notifications, { notify } from "react-notify-toast";
import Header from "../Components/Header";
import styles from "./styles/SetupStyles";
import { planTypes } from "../Utils/Types";
import { normalizeHost } from "../Utils/UserUtils";
import colors from "../Themes/Colors";
import { TwitterPicker } from "react-color";

import isURL from "validator/es/lib/isURL";
import isEmail from "validator/lib/isEmail";

const api = API.create();

const TITLE_LIMIT = 80;
const SUBTITLE_LIMIT = 80;
const BODY_LIMIT = 1000;
const SHARE_MESSAGE_LIMIT = 200;
const VERIFICATION_MESSAGE_LIMIT = 3000;
const NOTIFICATION_LENGTH = 3000;

class Setup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitlist: null,
      mailchimpDetails: null,
      domain: "",
      postmarkDomainName: "",
      dkimHost: "",
      dkimTextValu: "",
      dkimStatus: "",
      returnPathHost: "",
      returnPathValue: "",
      returnPathStatus: "",
      redirectUrl: "",
      companyName: "",
      uploadLogoStatus: false,
      fontColor: colors.black,
      backgroundColor: colors.white,
      title: "",
      subtitle: "",
      body: "",
      shareMessage: "",
      verificationMessage: "",
      seedCount: "",
      airtableBase: "",
      airtableApiKey: "",
      apiKey: "",
      uploadedUrl: "",
      uploadLogoMsg: "",
      logoLocalPath: "",
      audienceLists: [],
      selectedAudienceId: "",
      unSavedChanges: false,
      currentPlan: null,
      displayFontColorPicker: false,
      displayBackgroundColorPicker: false,
      showUpgradePlanDialog: false,
      showAPIRefreshConfirmationDialog: false,
      antiSpamEnabled: false
    };
  }

  componentDidMount = () => {
    ReactGA.pageview("setup");

    const waitlist = this.props.waitlist;

    api.getPlan((res) => {
      if (res.status === 200) {
        this.setState({ currentPlan: res.data.currentPlan });
      }
    });

    if (waitlist) {
      api.getWaitlistFromId(waitlist._id, (res) => {
        let waitlist = res.data;
        this.setState(
          {
            waitlist: waitlist,
            mailchimpDetails: waitlist.mailchimpDetails,
            domain: waitlist.domain,
            postmarkDomainName: waitlist.postmarkDNS.postmarkDomainName,
            dkimHost: waitlist.postmarkDNS.dkimHost,
            dkimTextValue: waitlist.postmarkDNS.dkimTextValue,
            dkimStatus: waitlist.postmarkDNS.dkimStatus,
            returnPathHost: waitlist.postmarkDNS.returnPathHost,
            returnPathValue: waitlist.postmarkDNS.returnPathValue,
            returnPathStatus: waitlist.postmarkDNS.returnPathStatus,
            redirectUrl: waitlist.redirectUrl,
            companyName: waitlist.companyName,
            uploadLogoStatus: false,
            fontColor: waitlist.theme ? waitlist.theme.font : colors.black,
            backgroundColor: waitlist.theme
              ? waitlist.theme.background
              : colors.white,
            title: waitlist.title,
            subtitle: waitlist.subtitle,
            body: waitlist.body,
            shareMessage: waitlist.shareMessage,
            verificationMessage: waitlist.verificationMessage,
            seedCount: waitlist.seedCount,
            airtableBase: waitlist.airtable ? waitlist.airtable.base : "",
            airtableApiKey: waitlist.airtable ? waitlist.airtable.apiKey : "",
            antiSpamEnabled: waitlist.antiSpamEnabled
          },
          () => {
            this.getAudienceLists();
          }
        );
      });
    }
  };

  getAudienceLists = () => {
    if (this.state.mailchimpDetails) {
      api.getAudienceLists(this.state.mailchimpDetails, (res) => {
        if (res.status === 200) {
          if (this.state.selectedAudienceId !== "") {
            if (this.props.unSavedChanges) {
              this.setState({
                audienceLists: res.data,
                selectedAudienceId: res.data[0].id
              });
            } else {
              this.setState({ audienceLists: res.data });
            }
          } else {
            this.setState({
              audienceLists: res.data,
              selectedAudienceId: res.data[0].id
            });
          }
        }
      });
    }
  };

  refreshToken = () => {
    api.refreshToken(this.state.waitlist._id, (res) => {
      if (res.status === 200) {
        this.setState({ apiKey: res.data });
      }
    });
  };

  previewWaitlistClicked = () => {
    let { waitlist } = this.state;
    if (waitlist) {
      let url = `https://referlist.co/joinwaitlist/${waitlist.domain}?email=example@referlist.co`;
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        url = `http://localhost:3000/joinwaitlist/${waitlist.domain}?email=example@referlist.co`;
      }

      window.open(url, "_blank");
    }
  };

  validateAndSave = async () => {
    const {
      domain,
      postmarkDomainName,
      redirectUrl,
      companyName,
      uploadLogoStatus,
      title,
      subtitle,
      body,
      shareMessage,
      verificationMessage,
      fontColor,
      backgroundColor,
      currentPlan,
      seedCount,
      airtableBase,
      airtableApiKey
    } = this.state;

    if (!domain) {
      notify.show("Please enter a domain name", "error", 3000);
      return;
    }
    var regex = /^[a-zA-Z0-9]+$/;
    if (!regex.test(domain)) {
      notify.show(
        "Your domain can only consist of alphabets and numbers",
        "error",
        3000
      );
      return;
    }

    if (domain.length < 3) {
      notify.show(
        "Your domain needs to be at least 3 characters long",
        "error",
        3000
      );
      return;
    }

    if (domain.length > 50) {
      notify.show(
        "Your domain can't be more than 50 characters",
        "error",
        3000
      );
      return;
    }

    if (
      redirectUrl === "" ||
      redirectUrl === null ||
      redirectUrl === undefined
    ) {
      notify.show(
        "Enter your website URL so that we can create a referral link for your users",
        "error",
        3000
      );
      return;
    }

    if (
      companyName === "" ||
      companyName === null ||
      companyName === undefined
    ) {
      notify.show("Please enter your company's name", "error", 3000);
      return;
    }

    let hasProtocol =
      redirectUrl.startsWith("http://") || redirectUrl.startsWith("https://");

    if (!this.validURL(hasProtocol ? redirectUrl : "https://" + redirectUrl)) {
      notify.show(
        "Enter a valid website URL like https://referlist.co",
        "error",
        3000
      );
      return;
    }

    if (postmarkDomainName) {
      if (!isEmail(`no-reply@${postmarkDomainName.trim()}`)) {
        notify.show(
          "Enter a valid domain for email verification like mail.referlist.co",
          "error",
          3000
        );
        return;
      }
    }

    if (airtableBase || airtableApiKey) {
      if (!airtableBase) {
        notify.show("Enter a valid base from Airtable", "error", 3000);
        return;
      }

      if (!airtableApiKey) {
        notify.show("Enter your Airtable key to save", "error", 3000);
        return;
      }
    }

    if (!uploadLogoStatus) {
      let showUpgradePlanDialog = false;
      // Using Pro features will trap the code here to show upgrade dialog
      if (
        currentPlan !== planTypes.PRO &&
        ((seedCount !== null &&
          seedCount !== "" &&
          parseInt(seedCount) !== 0) ||
          postmarkDomainName !== "")
      ) {
        this.setState({ seedCount: 0, postmarkDomainName: "" });
        showUpgradePlanDialog = true;
      }

      // if not using pro features or not in pro plan the user might be in free or growth plan
      // Below condition checks only if the user is free cause the growth plan allows the use of colors and custom message
      if (!showUpgradePlanDialog) {
        if (
          currentPlan === planTypes.FREE &&
          (title !== "" ||
            subtitle !== "" ||
            body !== "" ||
            shareMessage !== "" ||
            verificationMessage !== "" ||
            fontColor.toUpperCase() !== colors.black ||
            backgroundColor.toUpperCase() !== colors.white)
        ) {
          showUpgradePlanDialog = true;
        }
      }

      if (showUpgradePlanDialog) {
        this.setState({
          showUpgradePlanDialog: showUpgradePlanDialog
        });
      } else {
        // Reached here after validation so it can directly save the data from state
        this.saveData();
      }
    } else {
      // Upload in progress wait for it to complete
      window.alert("Uploading your logo. Give it a few seconds");
    }
  };

  saveData = () => {
    const {
      waitlist,
      domain,
      postmarkDomainName,
      redirectUrl,
      companyName,
      uploadedUrl,
      selectedAudienceId,
      mailchimpDetails,
      title,
      subtitle,
      body,
      shareMessage,
      verificationMessage,
      fontColor,
      backgroundColor,
      seedCount,
      airtableBase,
      airtableApiKey,
      antiSpamEnabled
    } = this.state;

    var waitlistId = null;
    if (waitlist) {
      waitlistId = waitlist._id;
    }

    ReactGA.event({
      category: "User",
      action: "Save setup"
    });

    let hasProtocol =
      redirectUrl.startsWith("http://") || redirectUrl.startsWith("https://");

    let newRedirectUrl = new URL(
      hasProtocol ? redirectUrl : "https://" + redirectUrl
    ).href;
    this.setState({
      redirectUrl: newRedirectUrl
    });

    api.saveWaitlistDetails(
      waitlistId,
      domain.toLowerCase(),
      postmarkDomainName ? postmarkDomainName.toLowerCase() : "",
      newRedirectUrl.toLowerCase(),
      companyName,
      uploadedUrl,
      mailchimpDetails,
      selectedAudienceId,
      title,
      subtitle,
      body,
      shareMessage,
      verificationMessage,
      fontColor,
      backgroundColor,
      seedCount,
      airtableBase,
      airtableApiKey,
      antiSpamEnabled,
      (res) => {
        if (res.status === 400) {
          if (res.data === "Domain exists") {
            notify.show(
              "Someone already picked that domain name. Try a variant of it",
              "error",
              3000
            );
          }
        } else if (res.status === 200) {
          notify.show("Setup saved", "success", 3000);
          waitlistId = res.data._id.toString();
          this.props.resetSetupValueChanged();
          this.props.reload(waitlistId);
        }
      }
    );
  };

  validURL = (url) => {
    try {
      return isURL(url);
    } catch (_) {
      return false;
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.props.setupValueChanged();
  };

  handleLowerCaseChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.toLowerCase() });
    this.props.setupValueChanged();
  };

  cancelAPIRefreshDialog = () => {
    this.setState({ showAPIRefreshConfirmationDialog: false });
  };

  handleSeedCountChange = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      if (
        parseInt(event.target.value) > 1000 ||
        parseInt(event.target.value) < 0
      ) {
        notify.show("Please enter values between 0 to 1000", "warning", 3000);
        return;
      }
      this.setState({ [event.target.name]: event.target.value });
      this.props.setupValueChanged();
    } else {
      notify.show("Your waitlist seed has to be a number", "warning", 3000);
    }
  };

  handleTitleChange = (event) => {
    if (event.target.value.length > TITLE_LIMIT) {
      event.target.value = event.target.value.substring(0, TITLE_LIMIT);
    }
    this.setState({ [event.target.name]: event.target.value });
    this.props.setupValueChanged();
  };

  handleSubtitleChange = (event) => {
    if (event.target.value.length > SUBTITLE_LIMIT) {
      event.target.value = event.target.value.substring(0, SUBTITLE_LIMIT);
    }
    this.setState({ [event.target.name]: event.target.value });
    this.props.setupValueChanged();
  };

  handleBodyChange = (event) => {
    if (event.target.value.length > BODY_LIMIT) {
      event.target.value = event.target.value.substring(0, BODY_LIMIT);
    }
    this.setState({ [event.target.name]: event.target.value });
    this.props.setupValueChanged();
  };

  handleShareMessageChange = (event) => {
    if (event.target.value.length > SHARE_MESSAGE_LIMIT) {
      event.target.value = event.target.value.substring(0, SHARE_MESSAGE_LIMIT);
    }
    this.setState({ [event.target.name]: event.target.value });
    this.props.setupValueChanged();
  };

  handleVerificationMessageChange = (event) => {
    if (event.target.value.length > VERIFICATION_MESSAGE_LIMIT) {
      event.target.value = event.target.value.substring(
        0,
        VERIFICATION_MESSAGE_LIMIT
      );
    }
    this.setState({ [event.target.name]: event.target.value });
    this.props.setupValueChanged();
  };

  onImageChange = (event) => {
    event.persist();
    let file = event.target.files[0];
    this.setState(
      {
        uploadLogoMsg: "Uploading your logo...",
        logoLocalPath: "",
        uploadLogoStatus: true
      },
      async () => {
        var uploadedUrl = await uploadImage("", file);
        this.setState(
          {
            uploadedUrl: uploadedUrl,
            uploadLogoMsg: "Uploaded " + file.name,
            uploadLogoStatus: false,
            logoLocalPath: URL.createObjectURL(file)
          },
          () => {
            this.props.setupValueChanged();
          }
        );
      }
    );
  };

  connectToMailchimp = () => {
    api.connectToMailchimp(this.state.waitlist._id.toString());
  };

  openIntegrationDocs = () => {
    window.open("https://docs.referlist.co/");
  };

  onCopy = () => {
    notify.show("Copied", "success", 3000);
  };

  handleFocus = (event) => event.target.select();

  handleBackgroundColorClick = () => {
    this.setState({
      displayBackgroundColorPicker: !this.state.displayColorPicker
    });
  };

  handleFontColorClick = () => {
    this.setState({ displayFontColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleBackgroundColorChange = (color) => {
    this.props.setupValueChanged();
    this.setState({
      backgroundColor: color.hex,
      displayBackgroundColorPicker: false
    });
  };

  handleFontColorChange = (color) => {
    this.setState({ fontColor: color.hex, displayFontColorPicker: false });
  };

  moveThemToBillingPage = () => {
    this.props.showBillingPage();
  };

  resetThemeColors = () => {
    this.setState(
      {
        backgroundColor: colors.white,
        fontColor: colors.black
      },
      () => {
        this.props.setupValueChanged();
      }
    );
  };

  resetEmailDomain = () => {
    let { waitlist } = this.props;
    api.resetEmailDomain(waitlist._id, (res) => {
      if (res.status === 200) {
        this.setState({
          postmarkDomainName: "",
          dkimHost: "",
          returnPathHost: ""
        });
      } else {
        notify.show(res.data, "error");
      }
    });
  };

  handleAntiSpamToggle = (event) => {
    this.setState({ antiSpamEnabled: event.target.checked }, () => {
      this.props.setupValueChanged();
    });
  };

  // checkDNSProbe = () => {
  //   let { publication } = this.props;
  //   api.getDomainStatus(publication._id, (res) => {
  //     if (res.status === 200) {
  //       if (
  //         res.data.customDomain.status === CustomDomainStatus.SUCCESS ||
  //         res.data.customDomain.status === CustomDomainStatus.FAILED ||
  //         res.data.customDomain.status === CustomDomainStatus.NOT_CONNECTED
  //       ) {
  //         clearInterval(this.checkDNSProbeInterval);
  //         delete this.checkDNSProbeInterval;
  //       }
  //       this.setState({
  //         customDomain: res.data.customDomain,
  //         customDomainProxy: res.data.customDomainProxy
  //       });
  //     }
  //   });
  // };

  render() {
    const {
      domain,
      postmarkDomainName,
      dkimHost,
      dkimTextValue,
      dkimStatus,
      returnPathHost,
      returnPathValue,
      returnPathStatus,
      redirectUrl,
      companyName,
      uploadedUrl,
      audienceLists,
      selectedAudienceId,
      mailchimpDetails,
      title,
      subtitle,
      body,
      shareMessage,
      verificationMessage,
      displayFontColorPicker,
      backgroundColor,
      fontColor,
      displayBackgroundColorPicker,
      showUpgradePlanDialog,
      waitlist,
      seedCount,
      airtableBase,
      airtableApiKey,
      apiKey,
      currentPlan,
      antiSpamEnabled
    } = this.state;

    const { classes } = this.props;
    var paletteColors = [
      "#D9E3F0",
      colors.white,
      "#F47373",
      "#697689",
      "#37D67A",
      "#2CCCE4",
      "#dce775",
      "#ff8a65",
      "#ba68c8",
      colors.black
    ];

    return (
      <Fragment>
        <Container className={classes.container}>
          <Notifications />
          <Header title="Setup" />
          <Grid container direction="row" alignItems="stretch">
            <Grid
              item
              className={[classes.width100, classes.setupGriditemStyle].join(
                " "
              )}
            >
              <Grid>
                <Typography variant="h6">Setup</Typography>
              </Grid>
              <Grid container direction="row" justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={waitlist === null || this.props.hasUnSavedChanges}
                  onClick={this.previewWaitlistClicked}
                  className={classes.previewBtnStyle}
                >
                  Preview
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.validateAndSave}
                  className={classes.saveBtnStyle}
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              className={[classes.width100, classes.marginTop20].join(" ")}
            >
              <Typography variant="subtitle2" className={classes.sectionHeader}>
                Required
              </Typography>

              <Paper
                className={[
                  classes.marginTop10,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Typography
                      variant="subtitle2"
                      className={classes.headerStyle}
                    >
                      Company
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      What's the name of your company?
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width35,
                        classes.padding10,
                        clsx(classes.textField, classes.dense)
                      ].join(" ")}
                      value={companyName}
                      placeholder="Referlist"
                      name="companyName"
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Typography
                      variant="subtitle2"
                      className={classes.headerStyle}
                    >
                      Website URL
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      What's the URL of your website where you'll host your
                      sign-up form?
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width35,
                        classes.padding10,
                        clsx(classes.textField, classes.dense)
                      ].join(" ")}
                      name="redirectUrl"
                      onChange={this.handleLowerCaseChange}
                      margin="dense"
                      value={redirectUrl}
                      placeholder="https://referlist.co"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Typography
                      variant="subtitle2"
                      className={classes.headerStyle}
                    >
                      Domain
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Pick a unique domain for your waitlist. This is usually
                      your company name or some variant of it
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width35,
                        classes.padding10,
                        clsx(classes.textField, classes.dense)
                      ].join(" ")}
                      value={domain}
                      placeholder="referlist"
                      name="domain"
                      onChange={this.handleLowerCaseChange}
                      margin="dense"
                      variant="outlined"
                    />

                    {domain && (
                      <Typography
                        variant="subtitle2"
                        className={[
                          classes.marginTop10,
                          classes.textStyle,
                          classes.padding10
                        ].join(" ")}
                      >
                        Your domain will appear in your waitlist page like so:
                        referlist.co/joinwaitlist/{domain}
                        ?email=example@referlist.co
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Typography
                      variant="subtitle2"
                      className={classes.headerStyle}
                    >
                      Anti Spam
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Add a Recaptcha on top of your signup form. If you also use a custom domain, you need to email it to support@referlist.co for this to work.
                    </Typography>
                    <Switch
                      id="outlined-dense"
                      checked={antiSpamEnabled}
                      onChange={this.handleAntiSpamToggle}
                      margin="dense"
                      variant="outlined"
                      name="antiSpam"
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Button
                variant="contained"
                color="primary"
                onClick={this.validateAndSave}
                className={[classes.center, classes.saveBtnStyle].join(" ")}
              >
                Save
              </Button>

              <Typography variant="subtitle2" className={classes.sectionHeader}>
                Optional
              </Typography>

              <Paper
                className={[
                  classes.marginTop10,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Typography
                      variant="subtitle2"
                      className={classes.headerStyle}
                    >
                      Your logo
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Upload a square 600x600 pixel logo (optional)
                    </Typography>

                    <Grid>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        style={{ display: "none" }}
                        type="file"
                        value=""
                        onChange={this.onImageChange}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          className={classes.button}
                        >
                          Upload
                        </Button>
                      </label>
                    </Grid>

                    <Typography
                      variant="subtitle2"
                      className={classes.padding10}
                    >
                      {this.state.uploadLogoMsg}
                    </Typography>

                    {uploadedUrl !== "" &&
                    uploadedUrl !== undefined &&
                    uploadedUrl !== null ? (
                      <Grid>
                        <img
                          id="target"
                          className={[
                            classes.padding10,
                            classes.logoImage
                          ].join(" ")}
                          src={uploadedUrl}
                          alt="Waitlist Log"
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Grid container direction="row" justify="space-between">
                      <Typography
                        variant="subtitle2"
                        className={classes.headerStyle}
                      >
                        Custom Message
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.proOnlyStyle}
                      >
                        PAID PLAN ONLY
                      </Typography>
                    </Grid>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Include a custom message for users who sign up for your
                      waitlist (optional)
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Title
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width80,
                        classes.padding10,
                        clsx(classes.textField, classes.dense),
                        classes.letterCountAdjustment
                      ].join(" ")}
                      value={title}
                      placeholder="Congrats! You're on the waitlist"
                      name="title"
                      onChange={this.handleTitleChange}
                      margin="dense"
                      variant="outlined"
                    />
                    <div className={classes.lettersCountStyle}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textStyle}
                      >
                        {title.length}/{TITLE_LIMIT}
                      </Typography>
                    </div>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Subtitle
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width80,
                        classes.padding10,
                        clsx(classes.textField, classes.dense),
                        classes.letterCountAdjustment
                      ].join(" ")}
                      value={subtitle}
                      placeholder="Want to get access sooner?"
                      name="subtitle"
                      onChange={this.handleSubtitleChange}
                      margin="dense"
                      variant="outlined"
                    />
                    <div className={classes.lettersCountStyle}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textStyle}
                      >
                        {subtitle.length}/{SUBTITLE_LIMIT}
                      </Typography>
                    </div>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Body
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width80,
                        classes.padding10,
                        clsx(classes.textField, classes.dense),
                        classes.letterCountAdjustment
                      ].join(" ")}
                      value={body}
                      placeholder="Move up the waitlist by sharing your referral link with your friends. The top 500 will get early access and a 20% discount on an annual membership"
                      name="body"
                      multiline={true}
                      rows={3}
                      rowsMax={4}
                      onChange={this.handleBodyChange}
                      margin="dense"
                      variant="outlined"
                    />
                    <div className={classes.lettersCountStyle}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textStyle}
                      >
                        {body.length}/{BODY_LIMIT}
                      </Typography>
                    </div>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Default share message in social links
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width80,
                        classes.padding10,
                        clsx(classes.textField, classes.dense),
                        classes.letterCountAdjustment
                      ].join(" ")}
                      value={shareMessage}
                      placeholder="I just joined the waitlist for new product. Use my link to sign up!"
                      name="shareMessage"
                      multiline={true}
                      rows={3}
                      rowsMax={4}
                      onChange={this.handleShareMessageChange}
                      margin="dense"
                      variant="outlined"
                    />
                    <div className={classes.lettersCountStyle}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textStyle}
                      >
                        {shareMessage.length}/{SHARE_MESSAGE_LIMIT}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Grid container direction="row" justify="space-between">
                      <Typography
                        variant="subtitle2"
                        className={classes.headerStyle}
                      >
                        Custom colors
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        className={classes.proOnlyStyle}
                      >
                        PAID PLAN ONLY
                      </Typography>
                    </Grid>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Set a custom background and font color (optional)
                    </Typography>
                    <Grid container direction="column">
                      <Grid item container direction="row">
                        {" "}
                        <div
                          className={classes.color}
                          style={{
                            background: backgroundColor,
                            border: "1px solid rgb(128, 123, 123)"
                          }}
                          ref="background"
                          onClick={this.handleBackgroundColorClick}
                        />
                        <Popover
                          id="color"
                          open={displayBackgroundColorPicker}
                          onClose={() =>
                            this.setState({
                              displayBackgroundColorPicker: false
                            })
                          }
                          anchorEl={this.refs.background}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                          }}
                          style={{ marginTop: 20 }}
                        >
                          <TwitterPicker
                            color={backgroundColor}
                            onChange={this.handleBackgroundColorChange}
                            colors={paletteColors}
                          />
                        </Popover>
                        <div
                          onClick={this.handleColorClick}
                          style={{ background: "#fff" }}
                          name="backgroundColor"
                        ></div>
                        <Typography
                          variant="subtitle2"
                          className={[
                            classes.textStyle,
                            classes.padding10
                          ].join(" ")}
                        >
                          Background
                        </Typography>
                      </Grid>
                      <Grid item container direction="row">
                        {" "}
                        <div
                          className={classes.color}
                          style={{
                            background: fontColor,
                            border: "1px solid rgb(128, 123, 123)"
                          }}
                          ref="font"
                          onClick={this.handleFontColorClick}
                        />
                        <Popover
                          id="color"
                          open={displayFontColorPicker}
                          onClose={() =>
                            this.setState({ displayFontColorPicker: false })
                          }
                          anchorEl={this.refs.font}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                          }}
                          style={{ marginTop: 20 }}
                        >
                          <TwitterPicker
                            color={fontColor}
                            onChange={this.handleFontColorChange}
                            colors={paletteColors}
                          />
                        </Popover>
                        <div
                          onClick={this.handleColorClick}
                          style={{ background: "#fff" }}
                          name="backgroundColor"
                        ></div>
                        <Typography
                          variant="subtitle2"
                          className={[
                            classes.textStyle,
                            classes.padding10
                          ].join(" ")}
                        >
                          Font
                        </Typography>
                      </Grid>

                      {(backgroundColor.toUpperCase() !== colors.white ||
                        fontColor.toUpperCase() !== colors.black) && (
                        <Grid item container direction="row">
                          <Button onClick={this.resetThemeColors}>Reset</Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Grid container direction="row" justify="space-between">
                      <Typography
                        variant="subtitle2"
                        className={classes.headerStyle}
                      >
                        Customize the verification email
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.proOnlyStyle}
                      >
                        PAID PLAN ONLY
                      </Typography>
                    </Grid>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      When someone signs up, we send them a verification email
                      to make sure they're a real human. Add a snippet to
                      customize the message (optional)
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Custom welcome message
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width80,
                        classes.padding10,
                        clsx(classes.textField, classes.dense),
                        classes.letterCountAdjustment
                      ].join(" ")}
                      value={verificationMessage}
                      placeholder="Hi there! Thanks for signing up for Robinhood. Verify your email to be placed on the list."
                      name="verificationMessage"
                      multiline={true}
                      rows={3}
                      rowsMax={4}
                      onChange={this.handleVerificationMessageChange}
                      margin="dense"
                      variant="outlined"
                    />
                    <div className={classes.lettersCountStyle}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textStyle}
                      >
                        {verificationMessage.length}/
                        {VERIFICATION_MESSAGE_LIMIT}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Grid container direction="row" justify="space-between">
                      <Typography
                        variant="subtitle2"
                        className={classes.headerStyle}
                      >
                        Send email from your domain
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.proOnlyStyle}
                      >
                        PRO PLAN ONLY
                      </Typography>
                    </Grid>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Send verification emails from your own domain (optional)
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Domain
                    </Typography>

                    <Grid container direction="row" justify="space-between">
                      <TextField
                        id="outlined-dense"
                        className={[
                          classes.marginTop10,
                          classes.width80,
                          classes.padding10,
                          clsx(classes.textField, classes.dense)
                        ].join(" ")}
                        value={postmarkDomainName}
                        placeholder="mail.referlist.co"
                        name="postmarkDomainName"
                        multiline={false}
                        rows={1}
                        rowsMax={1}
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                      />

                      {postmarkDomainName && (
                        <Button
                          onClick={this.resetEmailDomain}
                          className={classes.padding10}
                        >
                          Reset
                        </Button>
                      )}
                    </Grid>

                    {dkimHost && returnPathHost ? (
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Type</TableCell>
                              <TableCell>Key</TableCell>
                              <TableCell>Value</TableCell>
                              <TableCell>Verified</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>TXT</TableCell>
                              <TableCell>
                                <CopyToClipboard
                                  onCopy={this.onCopy}
                                  text={normalizeHost(
                                    dkimHost,
                                    postmarkDomainName
                                  )}
                                >
                                  <Tooltip title="Copy" placement="right">
                                    <div className={classes.overflowHidden}>
                                      {normalizeHost(
                                        dkimHost,
                                        postmarkDomainName
                                      )}
                                    </div>
                                  </Tooltip>
                                </CopyToClipboard>
                              </TableCell>

                              <TableCell>
                                <CopyToClipboard
                                  onCopy={this.onCopy}
                                  text={dkimTextValue}
                                >
                                  <Tooltip title="Copy" placement="right">
                                    <div className={classes.overflowHidden}>
                                      {dkimTextValue}
                                    </div>
                                  </Tooltip>
                                </CopyToClipboard>
                              </TableCell>
                              <TableCell>{dkimStatus.toString()}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>CNAME</TableCell>
                              <TableCell>
                                <CopyToClipboard
                                  onCopy={this.onCopy}
                                  text={normalizeHost(
                                    returnPathHost,
                                    postmarkDomainName
                                  )}
                                >
                                  <Tooltip title="Copy" placement="right">
                                    <div className={classes.overflowHidden}>
                                      {normalizeHost(
                                        returnPathHost,
                                        postmarkDomainName
                                      )}
                                    </div>
                                  </Tooltip>
                                </CopyToClipboard>
                              </TableCell>
                              <TableCell>
                                <CopyToClipboard
                                  onCopy={this.onCopy}
                                  text={returnPathValue}
                                >
                                  <Tooltip title="Copy" placement="right">
                                    <div className={classes.overflowHidden}>
                                      {returnPathValue}
                                    </div>
                                  </Tooltip>
                                </CopyToClipboard>
                              </TableCell>
                              <TableCell>
                                {returnPathStatus.toString()}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : null}
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Grid container direction="row" justify="space-between">
                      <Typography
                        variant="subtitle2"
                        className={classes.headerStyle}
                      >
                        Seed your waitlist
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.proOnlyStyle}
                      >
                        PRO PLAN ONLY
                      </Typography>
                    </Grid>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      No one likes an empty waitlist. You want to build FOMO for
                      your launch. You can add a number between 0 and 1000 so
                      that people feel like they're not the only ones excited
                      about your product. This doesn't count towards your export
                      limit
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width35,
                        classes.padding10,
                        clsx(classes.textField, classes.dense)
                      ].join(" ")}
                      value={seedCount}
                      name="seedCount"
                      onChange={this.handleSeedCountChange}
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Typography variant="subtitle2" className={classes.sectionHeader}>
                Integrations
              </Typography>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Typography
                      variant="subtitle2"
                      className={classes.headerStyle}
                    >
                      Mailchimp
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Connect your Mailchimp account to send email sign-ups to a
                      Mailchimp list
                    </Typography>

                    {mailchimpDetails ? (
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={this.connectToMailchimp}
                      >
                        Connect to different Mailchimp account
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.connectToMailchimp}
                        className={classes.connectBtnStyle}
                      >
                        Connect to Mailchimp
                      </Button>
                    )}
                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      {mailchimpDetails !== ""
                        ? audienceLists.length > 0
                          ? "Select audience list"
                          : "Create an audience list in Mailchimp to export emails"
                        : ""}
                    </Typography>
                    {audienceLists.length > 0 && (
                      <Select
                        native
                        name="selectedAudienceId"
                        onChange={this.handleChange}
                        onClick={this.handleChange}
                        value={
                          selectedAudienceId !== ""
                            ? selectedAudienceId
                            : audienceLists[0].id
                        }
                        className={[classes.width35, classes.margin10].join(
                          " "
                        )}
                      >
                        {audienceLists.map((item) => (
                          <option id={item.id} value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                        <option value="" />
                      </Select>
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Typography
                      variant="subtitle2"
                      className={classes.headerStyle}
                    >
                      Airtable
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Connect your Airtable account. Send email sign-ups to a
                      base with a table named "signups" and a single column
                      named "email".
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Airtable Base
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width35,
                        classes.padding10,
                        clsx(classes.textField, classes.dense)
                      ].join(" ")}
                      value={airtableBase}
                      placeholder="appXXXXXXXXXXXXXX"
                      name="airtableBase"
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                    />

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Airtable API Key
                    </Typography>

                    <TextField
                      id="outlined-dense"
                      className={[
                        classes.marginTop10,
                        classes.width35,
                        classes.padding10,
                        clsx(classes.textField, classes.dense)
                      ].join(" ")}
                      value={airtableApiKey}
                      placeholder="keyXXXXXXXXXXXXXX"
                      name="airtableApiKey"
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                className={[
                  classes.marginTop30,
                  classes.width100,
                  classes.paper
                ].join(" ")}
              >
                <Grid
                  container
                  className={[classes.padding20, classes.width100].join(" ")}
                >
                  <Grid item className={classes.width100}>
                    <Grid container direction="row" justify="space-between">
                      <Typography
                        variant="subtitle2"
                        className={classes.headerStyle}
                      >
                        API Key
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.proOnlyStyle}
                      >
                        PRO PLAN ONLY
                      </Typography>
                    </Grid>

                    <Typography
                      variant="subtitle2"
                      className={[
                        classes.marginTop10,
                        classes.textStyle,
                        classes.padding10
                      ].join(" ")}
                    >
                      Add sign-ups via API. Use this in a header called
                      "api-key" in your requests.
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      className={classes.apiKey}
                      value={
                        apiKey ? apiKey : "Hit refresh to generate new keys"
                      }
                      multiline={true}
                      contentEditable={false}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 10 }}>
                    <CopyToClipboard
                      onCopy={() =>
                        notify.show("Copied", "success", NOTIFICATION_LENGTH)
                      }
                      text={apiKey}
                    >
                      <IconButton>
                        <FileCopy />
                      </IconButton>
                    </CopyToClipboard>
                    <IconButton onClick={this.refreshToken}>
                      <RefreshIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>

              <Button
                variant="contained"
                color="primary"
                onClick={this.validateAndSave}
                className={[classes.center, classes.saveBtnStyle].join(" ")}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          <Dialog
            open={showUpgradePlanDialog}
            onClose={() => {
              this.setState({
                showUpgradePlanDialog: false
              });
            }}
          >
            <DialogTitle>
              You're trying to use features that you need to pay for
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Upgrade to the Growth plan to display a custom message or use
                custom colors. Upgrade to the Pro plan to set a seed count on
                your waitlist page or send email from a custom domain
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  if (currentPlan === planTypes.FREE) {
                    console.log("Resetting to free plan");
                    this.setState(
                      {
                        showUpgradePlanDialog: false,
                        title: "",
                        subtitle: "",
                        body: "",
                        shareMessage: "",
                        verificationMessage: "",
                        fontColor: colors.black,
                        backgroundColor: colors.white,
                        seedCount: 0,
                        postmarkDomainName: ""
                      },
                      () => this.saveData()
                    );
                  } else if (currentPlan === planTypes.GROWTH) {
                    console.log("Resetting to growth plan");
                    this.setState(
                      {
                        showUpgradePlanDialog: false,
                        seedCount: 0,
                        postmarkDomainName: ""
                      },
                      () => this.saveData()
                    );
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.moveThemToBillingPage}
                variant="contained"
                color="primary"
                autoFocus
              >
                Upgrade
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Fragment>
    );
  }
}

Setup = withStyles(styles)(Setup);

export default Setup;
