import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";

class ConfirmDialog extends Component {
  render() {
    let {
      show,
      title,
      message,
      successButtonName,
      optionButtonName
    } = this.props;

    return (
      <Dialog open={show} onClose={() => this.props.onCancel()} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => this.props.onCancel()}>
            Cancel
          </Button>
          {optionButtonName && (
            <Button onClick={() => this.props.onOption()}>
              {optionButtonName}
            </Button>
          )}
          <Button onClick={() => this.props.onSuccess()}>
            {successButtonName ? successButtonName : "Yes, I'm sure"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
