import Colors from "../../Themes/Colors";
import grey from "@material-ui/core/colors/grey";

const styles = theme => ({
  container: {
    height: "100vh",
    paddingLeft: 0,
    backgroundColor: Colors.backgroundColor
  },
  gridContainer: {
    height: "100vh",
    marginLeft: 0,
    paddingLeft: 0,
    marginRight: 0,
    paddingRight: 0
  },
  leftMenu: {
    backgroundColor: Colors.white,
    overflow: "hidden"
  },
  selectedComponentStyle: {
    backgroundColor: Colors.menuSelected,
    cursor: "pointer",
    height: 50,
    display: "flex",
    alignItems: "center",
    color: Colors.primary
  },
  selectedComponentText: {
    fontSize: 18,
    marginLeft: 30
  },
  unselectedComponentStyle: {
    cursor: "pointer",
    height: 50,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: Colors.menuSelected
    }
  },
  unselectedComponentText: {
    fontSize: 16,
    color: grey[600],
    marginLeft: 30
  },
  componentHolder: {
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
    [theme.breakpoints.up("sm")]: {
      height: "100vh",
      boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
      overflow: "auto"
    }
  },
  componentsNameDiv: {
    marginTop: 10
  },
  padding10: {
    padding: 10
  },
  marginTop10: {
    marginTop: 10
  },
  backButton: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  logo: {
    height: 24,
    marginTop: 20,
    marginLeft: 4
  },
  backArrow: {
    color: grey[500]
  },
  domain: {
    marginTop: 20,
    padding: 10,
    margin: "auto",
    fontSize: 24,
    color: Colors.primary,
    wordBreak: "break-word"
  }
});
export default styles;
