import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Typography, Button, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { withStyles } from "@material-ui/styles";
import styles from "./styles/CheckoutFormStyle";
import API from "../Services/Api";

const api = API.create();

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Open Sans, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#c23d4b"
      }
    }
  };
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: ""
    };
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  save = async ev => {
    ReactGA.event({
      category: "User",
      action: "Save card"
    });

    let { token } = await this.props.stripe.createToken();
    if (token) {
      api.saveCard(token.id, res => {
        if (res.status === 200) {
          this.setState({ haveCardOnFile: true });
          this.props.updateCallback(); // Inform the parent component to load the card details again from db
        }
      });
    }
  };

  render() {
    const { classes, haveCardOnFile } = this.props;

    return (
      <div className={classes.width100}>
        <Typography variant="h6" className={classes.headerStyle}>
          Payment Information
        </Typography>

        <div className={classes.cardFormContainer}>
          {haveCardOnFile ? (
            <Typography
              className={[classes.cardOnFile, classes.headerStyle].join(" ")}
            >
              You already have a card on file. Enter new card information to
              update it.
            </Typography>
          ) : (
            <Typography className={classes.cardOnFile}>
              Enter your credit card information.
            </Typography>
          )}
          <Paper className={classes.cardForm}>
            <CardElement onChange={this.handleChange} {...createOptions()} />
            <div className="error" role="alert">
              {this.state.errorMessage}
            </div>
          </Paper>
          <div className={classes.saveButton}>
            <Button variant="contained" color="primary" onClick={this.save}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

CheckoutForm.propTypes = {
  haveCardOnFile: PropTypes.bool.isRequired,
  updateCallback: PropTypes.func.isRequired
};

export default injectStripe(withStyles(styles)(CheckoutForm));
