import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import API from "../Services/Api";
import ReactGA from "react-ga";
import styles from "./styles/WaitlistsStyles";
import Notifications from "react-notify-toast";
import Images from "../Themes/Images";
import firebase from "firebase/app";
import { Add } from "@material-ui/icons";
import Drift from "react-driftjs";

const api = API.create();

const listIcons = [
  Images.listIcon1,
  Images.listIcon2,
  Images.listIcon3,
  Images.listIcon4,
  Images.listIcon5
];

class Waitlists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null
    };
  }

  componentDidMount = () => {
    this.load();
  };

  updateDimensions() {
    if (this.myRef.current !== null) {
      this.setState({
        height:
          window.pageYOffset +
          this.myRef.current.getBoundingClientRect().top +
          (this.myRef.current.getBoundingClientRect().bottom -
            this.myRef.current.getBoundingClientRect().top) /
            2
      });
    }
  }

  load = () => {
    api.getUserAndWaitlists((res) => {
      if (res.status === 200) {
        this.setState({ user: res.data });
      } else {
        this.signOutUser();
      }
    });
  };
  handleClose = () => {
    this.setState({ openDialog: false });
  };

  signOutUser = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.props.history.push("/login");
      });
  };

  openHome = (waitlist) => {
    if (waitlist === null) {
      ReactGA.event({
        category: "User",
        action: "Create waitlist"
      });
      this.props.history.push({ pathname: "/home/new" });
    } else {
      ReactGA.event({
        category: "User",
        action: "Open waitlist"
      });
      this.props.history.push({ pathname: "/home/" + waitlist._id });
    }
  };

  getRandomListIcon = () => {
    return listIcons[Math.floor(Math.random() * listIcons.length)];
  };

  render() {
    const { user } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Notifications options={{ zIndex: 1400 }} />
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.backgroundImage}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={2} sm={2} md={2}>
                <div>
                  <img
                    src={Images.logo}
                    alt="product logo"
                    className={classes.logo}
                  />
                </div>
              </Grid>
              <Grid item className={classes.width100} xs>
                <div className={classes.queueImageDiv}>
                  <img
                    src={Images.queueImage}
                    alt="queue illustration"
                    className={classes.queueImage}
                  ></img>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} container justify="flex-end">
                <Button
                  className={classes.logoutBtn}
                  onClick={this.signOutUser}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.waitlistsName}>Waitlists</div>
          </Grid>
          {user !== null && user !== undefined && (
            <Grid item className={classes.width100}>
              <Grid
                container
                className={[classes.waitlistsMargin].join(" ")}
                spacing={3}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Paper
                    className={
                      user.waitlists.length > 0
                        ? [classes.paper, classes.marginTop20].join(" ")
                        : [
                            classes.paper,
                            classes.marginTop20,
                            classes.height160
                          ].join(" ")
                    }
                    onClick={() => this.openHome(null)}
                    id="createpaper"
                    ref={this.myRef}
                  >
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={[
                            classes.textAlignCenter,
                            classes.createText,
                            classes.createWaitlistText
                          ].join(" ")}
                        >
                          Create a waitlist
                        </Typography>
                        <Add className={[classes.plusIcon].join(" ")} />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                {user.waitlists.length > 0 &&
                  user.waitlists.map((waitlist) => (
                    <Grid item xs={12} sm={6} md={4} key={waitlist._id}>
                      <Paper
                        className={[classes.paper, classes.marginTop20].join(
                          " "
                        )}
                        onClick={() => this.openHome(waitlist)}
                      >
                        <Grid
                          container
                          direction="column"
                          justify="flex-start"
                          alignItems="stretch"
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            <Typography
                              className={[
                                classes.textAlignCenter,
                                classes.marginTop10
                              ].join(" ")}
                            >
                              {waitlist.domain}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              className={[
                                classes.textAlignCenter,
                                classes.redirectText
                              ].join(" ")}
                            >
                              {
                                waitlist.redirectUrl
                                  .split("//")[1]
                                  .split("/")[0]
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <div className={classes.image}>
                              <img
                                className={classes.img}
                                alt="product logo"
                                src={
                                  waitlist.logo !== ""
                                    ? waitlist.logo
                                    : this.getRandomListIcon()
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
          <Grid item></Grid>
        </Grid>
        <Drift appId="rbfna89z24if" />
      </div>
    );
  }
}

Waitlists = withStyles(styles)(Waitlists);

export default Waitlists;
